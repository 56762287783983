import React, { useState } from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  }
}));

const SimpleSelect = ({ data, onChange, defaultSelected }) => {
  const classes = useStyles();
  const [formId, setFormId] = useState(defaultSelected);

  const handleChange = ({ target }) => {
    setFormId(target.value);
    onChange(target.value);
  };

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="demo-simple-select-label">Select form</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={formId}
        onChange={handleChange}
      >
        {data.map(form => (
          <MenuItem value={form.id} key={form.id}>
            {form.id} - {form.value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

SimpleSelect.propTypes = {
  data: PropTypes.array,
  onChange: PropTypes.func.isRequired
};

export default SimpleSelect;

import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { FormattedMessage } from "react-intl";

import { getReportForFormId, getAllForms } from "utils/api";
import ReportsComponent from "components/Reports";
import FullPageLoader from "components/FullPageLoader";
import SimpleSelect from "components/Select";

const getAllFormsIds = data =>
  data.map(form => ({ id: form.id, value: form.name }));

const getFormById = (formId, allForms) =>
  allForms.find(form => form.id === formId);

const AdminWrapper = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [allForms, setForms] = useState();
  const [selectedForm, setSelectedForm] = useState();
  const [isFetching, setFetchingData] = useState(false);

  const [reportData, setReportData] = useState({});
  const [isFetchingReport, setFetchingReports] = useState(false);

  useEffect(() => {
    setFetchingData(true);
    getAllForms()
      .then(({ data }) => {
        setForms(data);
        setSelectedForm(data[0]);

        setFetchingData(false);
        enqueueSnackbar(<FormattedMessage defaultMessage="Data loaded!"/>, {
          variant: "success"
        });
      })
      .catch(err => {
        enqueueSnackbar(
          <FormattedMessage defaultMessage="Data load failed!"/>,
          {
            variant: "error"
          }
        );
        setFetchingData(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectFormById = formId =>
    setSelectedForm(getFormById(formId, allForms));

  useEffect(() => {
    if (selectedForm?.id) {
      setFetchingReports(true);
      getReportForFormId(selectedForm?.id)
        .then(({ data }) => {
          setReportData(data);
          setFetchingReports(false);
          enqueueSnackbar(<FormattedMessage defaultMessage="Data loaded!"/>, {
            variant: "success"
          });
        })
        .catch(() => {
          enqueueSnackbar(
            <FormattedMessage defaultMessage="Data load failed!"/>,
            {
              variant: "error"
            }
          );
          setFetchingReports(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedForm]);

  if (isFetching || !selectedForm) return <FullPageLoader />;

  return (
    <>
      <SimpleSelect
        data={getAllFormsIds(allForms)}
        defaultSelected={selectedForm.id}
        onChange={handleSelectFormById}
      />
      <br />
      <br />
      {isFetchingReport ? (
        <FullPageLoader />
      ) : (
        <ReportsComponent data={reportData} />
      )}
    </>
  );
};

export default AdminWrapper;

const STORAGE_KEY = "languageCodeSurvey";

const set = language => {
  window.localStorage.setItem(STORAGE_KEY, language);
};

const get = () => {
  return window.localStorage.getItem(STORAGE_KEY);
};

export { get, set };

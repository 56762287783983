import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from "react-router-dom";
import { SnackbarProvider } from "notistack";
import { ThemeProvider } from "@material-ui/core/styles";
import { createMuiTheme } from "@material-ui/core/styles";

import EditSurvey from "pages/EditSurvey";
import Reports from "pages/Reports";
import Login from "pages/Login";
import ManageUsers from "pages/adminPages/ManageUsers";
import ManageForms from "pages/adminPages/ManageForms";
import UploadUsers from "pages/adminPages/UploadUsers";
import FormInvitations from "pages/adminPages/FormInvitations";
import PrivateRoute from "hocs/PrivateRoute";
import Intl from 'hocs/Intl';
import Layout from "components/Layout";

import RoutePaths from "enums/routes";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#1690C9"
    },
    secondary: {
      main: "#0fbf93"
    }
  }
});

const App = () => {
  return (
    <Intl>
      <ThemeProvider theme={theme}>
        <Router>
          <SnackbarProvider
            autoHideDuration={2000}
            maxSnack={1}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center"
            }}
          >
            <Layout>
              <Switch>
                <PrivateRoute
                  exact
                  path={RoutePaths.EDIT_SURVEY}
                  component={EditSurvey}
                />
                <PrivateRoute
                  exact
                  path={RoutePaths.REPORTS}
                  component={Reports}
                />
                <PrivateRoute
                  exact
                  path={RoutePaths.MANAGE_USERS}
                  component={ManageUsers}
                />
                <PrivateRoute
                  exact
                  path={RoutePaths.MANAGE_FORMS}
                  component={ManageForms}
                />
                <PrivateRoute
                  exact
                  path={RoutePaths.UPLOAD_USERS}
                  component={UploadUsers}
                />
                <PrivateRoute
                  exact
                  path={RoutePaths.FORM_INVITATIONS}
                  component={FormInvitations}
                />
                <Route exact path={RoutePaths.LOGIN} component={Login} />
                <Redirect from="/" to={RoutePaths.MANAGE_FORMS} />
              </Switch>
            </Layout>
          </SnackbarProvider>
        </Router>
      </ThemeProvider>
    </Intl>
  );
};

export default App;

import React from "react";

import { isAdmin } from "utils/Auth";

import AdminForm from "containers/reports/Admin";
import UserForm from "containers/reports/User";

const EditSurveyPage = () => {
  if (isAdmin()) return <AdminForm />;

  return <UserForm />;
};

export default EditSurveyPage;

import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { FormattedMessage } from "react-intl";

import { getReportForFormId } from "utils/api";
import { getUserFormId } from "utils/Auth";
import ReportsComponent from "components/Reports";
import FullPageLoader from "components/FullPageLoader";

const UserWrapper = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [data, setdata] = useState();
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getReportForFormId(getUserFormId())
      .then(response => {
        setLoading(false);
        setdata(response.data);
        enqueueSnackbar(<FormattedMessage defaultMessage="Data loaded!"/>, {
          variant: "success"
        });
      })
      .catch(() => {
        setLoading(false);
        enqueueSnackbar(
          <FormattedMessage defaultMessage="Data load failed!"/>,
          {
            variant: "error"
          }
        );
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) return <FullPageLoader />;
  return <ReportsComponent data={data} />;
};

export default UserWrapper;

import DEFAULT_LOGO from "enums/logo";
export const getDefaultEngForm = (formId, clientName) => {
  return {
    id: formId,
    name: clientName,
    questions: [
      {
        id: 1,
        title: "Sharing Information",
        name: "Enter the name of those people who regularly share with you information you need to do your job.",
        type: "multiSelect",
        options: []
      },
      {
        id: 2,
        title: "Technical Support",
        name:
          "Enter the name of those people you go to when you need technical / expert support related to the job that needs specialized knowledge.Enter the name of those people you go to when you need technical / expert support related to the job that needs specialized knowledge.Enter the name of those people you go to when you need technical / expert support related to the job that needs specialized knowledge.",
        type: "multiSelect",
        options: []
      },
      {
        id: 3,
        title: "Personal Support",
        name:
          "Enter the name of those people you go to when you need personal support in a difficult work-related situation.Enter the name of those people you go to when you need personal support in a difficult work-related situation.",
        type: "multiSelect",
        options: []
      },
      {
        id: 4,
        title: "Inspiration",
        name:
          "Enter the name of those people who inspire you and whom you consider a role model within the organization.Enter the name of those people who inspire you and whom you consider a role model within the organization.",
        type: "multiSelect",
        options: []
      }
    ],
    options: [],
    welcomePageString:
      '<h1 style="text-align:center;"><span style="color: rgb(209,72,65);">Welcome page title!</span></h1>',
    welcomePageString2:
      '<h1 style="text-align:center;"><span style="color: rgb(209,72,65);">GDPR section</span></h1>',
    settings: {
      primaryColor: "#393136",
      secondaryColor: "#18ac8f",
      logoBase64: DEFAULT_LOGO,
      language: 'en'
    },
    isLocked: false
  };
};
export const getDefaultEspForm = (formId, clientName) => {
  return {
    id: formId,
    name: clientName,
    questions: [
      {
        id: 1,
        title: "Compartir información",
        name: "Escribe el nombre de aquellas personas que, de forma regular, comparten contigo la información que necesitas para hacer tu trabajo.",
        type: "multiSelect",
        options: []
      },
      {
        id: 2,
        title: "Apoyo técnico / experto",
        name:
          "Escribe el nombre de aquellas personas a las que acudes cuando necesitas apoyo técnico o conocimiento especializado experto relacionado con el trabajo.",
        type: "multiSelect",
        options: []
      },
      {
        id: 3,
        title: "Apoyo personal",
        name:
          "Escribe el nombre de aquellas personas a las que acudes cuando necesitas apoyo personal en una situación difícil relacionada con el trabajo.",
        type: "multiSelect",
        options: []
      },
      {
        id: 4,
        title: "Inspiración",
        name:
          "Escribe el nombre de aquellas personas que te inspiran y a las que consideras un modelo a seguir dentro de la organización.",
        type: "multiSelect",
        options: []
      }
    ],
    options: [],
    welcomePageString:
      '<h1 style="text-align:center;"><span style="color: rgb(209,72,65);">Welcomo page in the ESPANOLO!</span></h1>',
    welcomePageString2:
      '<h1 style="text-align:center;"><span style="color: rgb(209,72,65);">GDPR section</span></h1>',
    settings: {
      primaryColor: "#393136",
      secondaryColor: "#18ac8f",
      logoBase64: DEFAULT_LOGO,
      language: 'es'
    },
    isLocked: false
  };
};

export const getDefaultBrForm = (formId, clientName) => {
  return {
    id: formId,
    name: clientName,
    questions: [
      {
        id: 1,
        title: "Partilhando informação",
        name: "Digite o nome das pessoas que compartilham regularmente com você as informações de que você precisa para fazer seu trabalho.",
        type: "multiSelect",
        options: []
      },
      {
        id: 2,
        title: "Suporte técnico",
        name:
          "Digite o nome das pessoas que você procura quando precisa de suporte técnico / especializado relacionado ao trabalho que requer conhecimento especializado. Digite o nome das pessoas que você procura quando precisa de suporte técnico / especializado relacionado ao trabalho que requer conhecimento especializado. Insira o nome das pessoas que você procura quando precisa de suporte técnico / especializado relacionado ao trabalho que requer conhecimento especializado.",
        type: "multiSelect",
        options: []
      },
      {
        id: 3,
        title: "Apoio Pessoal",
        name:
          "Digite o nome das pessoas a quem você recorre quando precisa de apoio pessoal em uma situação difícil de trabalho. Digite o nome das pessoas a quem você recorre quando precisa de apoio pessoal em uma situação difícil de trabalho.",
        type: "multiSelect",
        options: []
      },
      {
        id: 4,
        title: "Inspiração",
        name:
          "Insira o nome das pessoas que o inspiram e que você considera um modelo de papel dentro da organização. Insira o nome das pessoas que o inspiram e que você considera um modelo de papel dentro da organização.",
        type: "multiSelect",
        options: []
      }
    ],
    options: [],
    welcomePageString:
      '<h1 style="text-align:center;"><span style="color: rgb(209,72,65);">Título da página de boas-vindas!</span></h1>',
    welcomePageString2:
      '<h1 style="text-align:center;"><span style="color: rgb(209,72,65);">Seção GDPR</span></h1>',
    settings: {
      primaryColor: "#393136",
      secondaryColor: "#18ac8f",
      logoBase64: DEFAULT_LOGO,
      language: 'br'
    },
    isLocked: false
  };
}

export const getDefaultItForm = (formId, clientName) => {
  return {
    id: formId,
    name: clientName,
    questions: [
      {
        id: 1,
        title: "Condividere informazioni",
        name: "Inserisci il nome delle persone che condividono regolarmente con te le informazioni di cui hai bisogno per svolgere il tuo lavoro.",
        type: "multiSelect",
        options: []
      },
      {
        id: 2,
        title: "Supporto tecnico",
        name:
          "Inserisci il nome delle persone a cui ti rivolgi quando hai bisogno di assistenza tecnica/esperta relativa al lavoro che richiede conoscenze specialistiche.Inserisci il nome delle persone a cui ti rivolgi quando hai bisogno di assistenza tecnica/esperta relativa al lavoro che richiede conoscenze specialistiche. Inserisci il nome delle persone a cui ti rivolgi quando hai bisogno di supporto tecnico/esperto relativo al lavoro che necessita di conoscenze specialistiche.",
        type: "multiSelect",
        options: []
      },
      {
        id: 3,
        title: "Supporto personale",
        name:
          "Inserisci il nome delle persone a cui ti rivolgi quando hai bisogno di supporto personale in una situazione lavorativa difficile.Inserisci il nome delle persone a cui ti rivolgi quando hai bisogno di supporto personale in una situazione lavorativa difficile.",
        type: "multiSelect",
        options: []
      },
      {
        id: 4,
        title: "Ispirazione",
        name:
          "Inserisci il nome delle persone che ti ispirano e che consideri un modello all'interno dell'organizzazione.Inserisci il nome delle persone che ti ispirano e che consideri un modello all'interno dell'organizzazione.",
        type: "multiSelect",
        options: []
      }
    ],
    options: [],
    welcomePageString:
      '<h1 style="text-align:center;"><span style="color: rgb(209,72,65);">Titolo pagina di benvenuto!</span></h1>',
    welcomePageString2:
      '<h1 style="text-align:center;"><span style="color: rgb(209,72,65);">Sezione GDPR</span></h1>',
    settings: {
      primaryColor: "#393136",
      secondaryColor: "#18ac8f",
      logoBase64: DEFAULT_LOGO,
      language: 'it'
    },
    isLocked: false
  };
};
import React, { useState } from "react";
import { useSnackbar } from "notistack";
import { Redirect } from "react-router-dom";
import axios from "axios";
import { FormattedMessage } from "react-intl";

import LoginForm from "components/Login/Form";
import { setSessionToken, isUserLogged, isAdmin } from "utils/Auth";
import RoutePaths from "enums/routes";

const Login = ({ history, ...props }) => {
  const [isLoading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleLoginUser = ({ email, password }) => {
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}/login`, {
        login: email,
        password: password
      })
      .then(({ data }) => {
        setSessionToken(data.token);
        if (isAdmin(data.token)) {
          history.push(RoutePaths.MANAGE_FORMS);
        } else {
          history.push(RoutePaths.EDIT_SURVEY);
        }

        enqueueSnackbar(<FormattedMessage defaultMessage="Logged in app"/>, {
          variant: "success"
        });
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        enqueueSnackbar(
          <FormattedMessage defaultMessage="Invalid credentials!"/>,
          {
            variant: "error"
          }
        );
      });
  };

  if (isUserLogged()) return <Redirect to={RoutePaths.edit_survey} />;

  return <LoginForm onSubmit={handleLoginUser} isLoading={isLoading} />;
};

export default Login;

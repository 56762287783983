import React from "react";

import styled from "styled-components";
import { FormattedMessage } from "react-intl";

const Wrapper = styled.div`
  width: 100%;
  padding: 40px;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
`;

const NoData = () => {
  return (
    (<Wrapper>
      <FormattedMessage defaultMessage="No data!"/>
    </Wrapper>)
  );
};

export default NoData;

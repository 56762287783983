import React from "react";
import PropTypes from "prop-types";
import { Button, Grid } from "@material-ui/core";
import { Formik, Field, Form } from "formik";
import { TextField } from "formik-material-ui";
import * as Yup from "yup";
import logo from "images/logo-small.png";
import { FormattedMessage } from "react-intl";

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email(<FormattedMessage defaultMessage="Invalid email"/>)
    .required(<FormattedMessage defaultMessage="Required"/>),
  password: Yup.string()
    .min(2, <FormattedMessage defaultMessage="Too short!"/>)
    .max(100, <FormattedMessage defaultMessage="Too long!"/>)
    .required(<FormattedMessage defaultMessage="Required"/>),
});

const LoginForm = ({ isLoading, onSubmit }) => {
  return (
    (<Grid container spacing={5} justify="center">
      <img src={logo} alt="logo" width={242.6} height={67.2} />
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={LoginSchema}
        onSubmit={onSubmit}
      >
        {({ submitForm }) => (
          <Grid container justify="center">
            <Form style={{ width: "100%", maxWidth: "800px" }}>
              <Grid item xs={12}>
                <br />
                <Field
                  component={TextField}
                  name="email"
                  type="email"
                  label={<FormattedMessage defaultMessage="Email"/>}
                  fullWidth
                  variant="outlined"
                  multiline
                  margin="dense"
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  component={TextField}
                  type="password"
                  label={<FormattedMessage defaultMessage="Password"/>}
                  name="password"
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  disabled={isLoading}
                />
              </Grid>
              <br />
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  size="large"
                  disabled={isLoading}
                  onClick={submitForm}
                >
                  {isLoading ? (
                    <FormattedMessage defaultMessage="Loading..."/>
                  ) : (
                    <FormattedMessage defaultMessage="Login"/>
                  )}
                </Button>
              </Grid>
            </Form>
          </Grid>
        )}
      </Formik>
    </Grid>)
  );
};

LoginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

export default LoginForm;

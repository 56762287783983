import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { useSnackbar } from "notistack";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";

import RoutePaths from "enums/routes";
import FullPageLoader from "components/FullPageLoader";
import ManageForms from "components/ManageForms";
import Modal from "components/Modal";
import AddFormModal from "components/ManageForms/AddFormModal";
import SendFormModal from "components/ManageForms/SendFormModal";
import SetSSOTypeModal from "components/ManageForms/SetSSOTypeModal";
import InfoModal from "components/ManageForms/InfoModal";
import IconButton from "@material-ui/core/IconButton";

import { isAdmin } from "utils/Auth";
import {
  getAllForms,
  removeFormById,
  lockFormById,
  unlockFormById,
  blockFormById,
  unblockFormById,
  createNewForm,
  sendFormById,
  setSSOTypeByFormId,
} from "utils/api";

const ActionWrapper = styled.div`
  position: absolute;
  bottom: 50px;
  right: 50px;
`;
const InfoWrapper = styled.div`
  position: absolute;
  top: 80px;
  right: 50px;
`;

const getFormStatus = (id, forms) =>
  forms.find((form) => form.id === id).isLocked;

const getFormBlockStatus = (id, forms) =>
  forms.find((form) => form.id === id).isBlocked;

const ManageFormsPage = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [isFetching, setFetching] = useState(false);
  const [isRemovingForm, setRemovingForm] = useState(false);
  const [removeFormId, setRemoveFormId] = useState(null);

  const [isLockingForm, setLockingForm] = useState(false);
  const [lockFormId, setLockFormId] = useState(null);

  const [isBlockingForm, setBlockingForm] = useState(false);
  const [blockFormId, setBlockFormId] = useState(null);

  const [isAddFormVisible, setAddFormVisible] = useState(false);
  const [isCreatingForm, setIsCreatingForm] = useState(false);

  const [sendFormId, setSendFormId] = useState(null);
  const [isSendingForm, setSendingForm] = useState(false);

  const [ssoTypeFormId, setSSOTypeFormId] = useState(null);
  const [isSettingSSOType, setSettingSSOType] = useState(false);

  const [isInfoModalVisible, setInfoModalVisible] = useState(false);

  const [forms, setForms] = useState([]);

  useEffect(() => {
    setFetching(true);
    getAllForms()
      .then(({ data }) => {
        setFetching(false);
        setForms(data);

        enqueueSnackbar(
          <FormattedMessage defaultMessage="Forms data loaded!"/>,
          {
            variant: "success",
          }
        );
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          enqueueSnackbar(
            <FormattedMessage
              defaultMessage="You are unauthorized to see this page!"
              id="You_are_unauthorized_to_see_this_page!" />,
            {
              variant: "warning",
            }
          );
        } else {
          enqueueSnackbar(
            <FormattedMessage defaultMessage="Something went wrong!"/>,
            {
              variant: "error",
            }
          );
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRemoveForm = () => {
    setRemovingForm(true);
    removeFormById(removeFormId)
      .then((response) => {
        const filteredForms = forms.filter((form) => form.id !== removeFormId);
        setForms(filteredForms);
        setRemovingForm(false);
        setRemoveFormId(null);
        enqueueSnackbar(
          <FormattedMessage defaultMessage="Form was removed!"/>,
          {
            variant: "success",
          }
        );
      })
      .catch(({ response }) => {
        if (response?.status === 409) {
          enqueueSnackbar(
            <FormattedMessage
              defaultMessage="Form is assigned to somed user!"
              id="Form_is_assigned_to_somed_user!" />,
            {
              variant: "warning",
            }
          );
        } else {
          enqueueSnackbar(
            <FormattedMessage defaultMessage="Form wasn't removed"/>,
            {
              variant: "error",
            }
          );
        }
        setRemovingForm(false);
        setRemoveFormId(null);
      });
  };

  const handleLockForm = () => {
    setLockingForm(true);
    const filteredForms = forms.filter((form) => form.id !== lockFormId);
    const newForm = forms.find((form) => form.id === lockFormId);
    const oldFormState = newForm.isLocked;
    newForm.isLocked = !oldFormState;
    filteredForms.push(newForm);

    if (!oldFormState) {
      lockFormById(lockFormId)
        .then(() => {
          setForms(filteredForms);
          setLockingForm(false);
          setLockFormId(null);
          enqueueSnackbar(
            <FormattedMessage defaultMessage="Form was locked!"/>,
            {
              variant: "success",
            }
          );
        })
        .catch((err) => {
          enqueueSnackbar(
            <FormattedMessage defaultMessage="Something went wrong!"/>,
            {
              variant: "error",
            }
          );
        });
    } else {
      unlockFormById(lockFormId)
        .then(() => {
          setForms(filteredForms);
          setLockingForm(false);
          setLockFormId(null);
          enqueueSnackbar(
            <FormattedMessage defaultMessage="Form was unlocked!"/>,
            {
              variant: "success",
            }
          );
        })
        .catch((err) => {
          enqueueSnackbar(
            <FormattedMessage defaultMessage="Something went wrong!"/>,
            {
              variant: "error",
            }
          );
        });
    }
  };

  const handleBlockForm = () => {
    setBlockingForm(true);
    const filteredForms = forms.filter((form) => form.id !== blockFormId);
    const newForm = forms.find((form) => form.id === blockFormId);
    const oldFormState = newForm.isBlocked;
    newForm.isBlocked = !oldFormState;
    filteredForms.push(newForm);

    if (!oldFormState) {
      blockFormById(blockFormId)
        .then(() => {
          setForms(filteredForms);
          setBlockingForm(false);
          setBlockFormId(null);
          enqueueSnackbar(
            <FormattedMessage defaultMessage="Form was blocked!"/>,
            {
              variant: "success",
            }
          );
        })
        .catch((err) => {
          enqueueSnackbar(
            <FormattedMessage defaultMessage="Something went wrong!"/>,
            {
              variant: "error",
            }
          );
        });
    } else {
      unblockFormById(blockFormId)
        .then(() => {
          setForms(filteredForms);
          setBlockingForm(false);
          setBlockFormId(null);
          enqueueSnackbar(
            <FormattedMessage defaultMessage="Form was unblocked!"/>,
            {
              variant: "success",
            }
          );
        })
        .catch((err) => {
          enqueueSnackbar(
            <FormattedMessage defaultMessage="Something went wrong!"/>,
            {
              variant: "error",
            }
          );
        });
    }
  };

  const handleSubmitNewForm = (formData) => {
    setIsCreatingForm(true);
    createNewForm(formData)
      .then(() => {
        setIsCreatingForm(false);
        setAddFormVisible(false);
        const newForms = [...forms, formData];
        setForms(newForms);
        enqueueSnackbar(<FormattedMessage defaultMessage="Form was added!"/>, {
          variant: "success",
        });
      })
      .catch(() => {
        setIsCreatingForm(false);
        setAddFormVisible(false);
        enqueueSnackbar(
          <FormattedMessage defaultMessage="Something went wrong!"/>,
          {
            variant: "error",
          }
        );
      });
  };

  const handleSubmitSendForm = (template, subject) => {
    setSendingForm(true);
    sendFormById(sendFormId, template, subject)
      .then((response) => {
        setSendingForm(false);
        setSendFormId(null);
        enqueueSnackbar(
          <FormattedMessage defaultMessage="Form was added to queue!"/>,
          {
            variant: "success",
          }
        );
      })
      .catch((error) => {
        console.error(error); 
        setSendingForm(false);
        setSendFormId(null);
        enqueueSnackbar(
          <FormattedMessage defaultMessage="Something went wrong!"/>,
          {
            variant: "error",
          }
        );
      });
  };

  const handleSubmitSSOType = (ssoType) => {
    setSettingSSOType(true);
    setSSOTypeByFormId(ssoTypeFormId, ssoType)
      .then((response) => {
        const filteredForms = forms.filter((form) => form.id !== ssoTypeFormId);
        const newForm = forms.find((form) => form.id === ssoTypeFormId);
        newForm.ssoType = ssoType;
        filteredForms.push(newForm);

        setSettingSSOType(false);
        setSSOTypeFormId(null);
        enqueueSnackbar(
          <FormattedMessage defaultMessage="SSO type was set!"/>,
          {
            variant: "success",
          }
        );
      })
      .catch(() => {
        setSettingSSOType(false);
        setSSOTypeFormId(null);
        enqueueSnackbar(
          <FormattedMessage defaultMessage="Something went wrong!"/>,
          {
            variant: "error",
          }
        );
      });
  };

  if (!isAdmin()) {
    return <Redirect to={RoutePaths.EDIT_SURVEY} />;
  }

  if (isFetching) return <FullPageLoader />;
  if (!forms) return <FormattedMessage defaultMessage="No forms found!"/>;

  return (<>
    <ManageForms
      forms={forms}
      onRemoveForm={setRemoveFormId}
      onClickLockForm={setLockFormId}
      onSendForm={setSendFormId}
      onClickBlockForm={setBlockFormId}
      onClickSSOType={setSSOTypeFormId}
    />
    <ActionWrapper>
      <Fab
        color="primary"
        aria-label="add"
        onClick={() => setAddFormVisible(true)}
      >
        <AddIcon />
      </Fab>
    </ActionWrapper>
    <InfoWrapper>
      <IconButton onClick={() => setInfoModalVisible(true)}>
        <HelpOutlineIcon fontSize="large" color="secondary" />
      </IconButton>
    </InfoWrapper>
    {removeFormId && (
      <Modal
        title={<FormattedMessage defaultMessage="Remove form"/>}
        description={`Do you really want to remove formId: ${removeFormId}`}
        isLoading={isRemovingForm}
        onSubmit={handleRemoveForm}
        onClose={() => setRemoveFormId(null)}
      />
    )}
    {lockFormId && (
      <Modal
        title={<FormattedMessage defaultMessage="Lock/Unlock form"/>}
        description={`Do you really want to ${
          getFormStatus(lockFormId, forms) ? "unlock" : "lock"
        } formId: ${lockFormId}`}
        isLoading={isLockingForm}
        onSubmit={handleLockForm}
        onClose={() => setLockFormId(null)}
      />
    )}
    {blockFormId && (
      <Modal
        title={<FormattedMessage defaultMessage="Block/Unblock form"/>}
        description={`Do you really want to ${
          getFormBlockStatus(blockFormId, forms) ? "unblock" : "block"
        } formId: ${blockFormId}`}
        isLoading={isBlockingForm}
        onSubmit={handleBlockForm}
        onClose={() => setBlockFormId(null)}
      />
    )}
    {isAddFormVisible && (
      <AddFormModal
        allFormsIds={forms.map((form) => form.id)}
        isLoading={isCreatingForm}
        onSubmit={handleSubmitNewForm}
        onClose={() => setAddFormVisible(false)}
      />
    )}
    {sendFormId && (
      <SendFormModal
        isLoading={isSendingForm}
        onSubmit={handleSubmitSendForm}
        onClose={() => setSendFormId(null)}
      />
    )}
    {isInfoModalVisible && (
      <InfoModal onClose={() => setInfoModalVisible(false)} />
    )}
    {ssoTypeFormId && (
      <SetSSOTypeModal
        isLoading={isSettingSSOType}
        onSubmit={handleSubmitSSOType}
        onClose={() => setSSOTypeFormId(null)}
        ssoType={forms.find((form) => form.id === ssoTypeFormId)?.ssoType}
      />
    )}
  </>);
};

export default ManageFormsPage;

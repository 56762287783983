import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { FormattedMessage } from "react-intl";

import EditSurvey from "components/EditSurvey";

import FullPageLoader from "components/FullPageLoader";
import { getForm, updateForm } from "utils/api";

const UserWrapperForm = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [isUpdatingForm, setUpdatingForm] = useState(false);
  const [isFetchingData, setFetchingData] = useState(false);
  const [data, setData] = useState(null);

  const handleSubmitQuestions = ({ questions, options }) => {
    setUpdatingForm(true);
    // potrebujem v povodnom formate posielat!!!
    const updatedData = {
      ...data,
      name: data.name,
      questions,
      options,
    };

    updateForm(updatedData)
      .then((resp) => {
        setUpdatingForm(false);
        setData({
          ...data,
          ...updatedData,
        });
        enqueueSnackbar(
          <FormattedMessage
            defaultMessage="Survey questions updated successfull!"
            id="Survey_questions_updated_successfull!" />,
          {
            variant: "success",
          }
        );
      })
      .catch((err) => {
        setUpdatingForm(false);
        enqueueSnackbar(
          <FormattedMessage
            defaultMessage="Failed to update data. Try again later!"
            id="Failed_to_update_data._Try_again_later!" />,
          {
            variant: "error",
          }
        );
      });
  };

  const handleSubmitWelcomePage = (welcomePageString) => {
    setUpdatingForm(true);
    const updatedData = {
      ...data,
      welcomePageString,
    };
    updateForm(updatedData)
      .then(() => {
        setUpdatingForm(false);
        setData({
          ...data,
          ...updatedData,
        });
        enqueueSnackbar(
          <FormattedMessage
            defaultMessage="Survey welcome page updated successfull!"
            id="Survey_welcome_page_updated_successfull!" />,
          {
            variant: "success",
          }
        );
      })
      .catch((err) => {
        setUpdatingForm(false);
        enqueueSnackbar(
          <FormattedMessage
            defaultMessage="Failed to update data. Try again later!"
            id="Failed_to_update_data._Try_again_later!" />,
          {
            variant: "error",
          }
        );
      });
  };

  const handleSubmitWelcomePage2 = (welcomePageString2) => {
    setUpdatingForm(true);
    const updatedData = {
      ...data,
      welcomePageString2,
    };
    updateForm(updatedData)
      .then(() => {
        setUpdatingForm(false);
        setData({
          ...data,
          ...updatedData,
        });
        enqueueSnackbar(
          <FormattedMessage
            defaultMessage="Survey welcome page updated successfull!"
            id="Survey_welcome_page_updated_successfull!" />,
          {
            variant: "success",
          }
        );
      })
      .catch((err) => {
        setUpdatingForm(false);
        enqueueSnackbar(
          <FormattedMessage
            defaultMessage="Failed to update data. Try again later!"
            id="Failed_to_update_data._Try_again_later!" />,
          {
            variant: "error",
          }
        );
      });
  };

  const handleColorsPage = (settings) => {
    setUpdatingForm(true);
    const updatedData = {
      ...data,
      settings,
    };
    updateForm(updatedData)
      .then(() => {
        setUpdatingForm(false);
        setData({
          ...data,
          ...updatedData,
        });
        enqueueSnackbar(
          <FormattedMessage
            defaultMessage="Survey colors and logo updated successfull!"
            id="Survey_colors_and_logo_updated_successfull!" />,
          {
            variant: "success",
          }
        );
      })
      .catch((err) => {
        setUpdatingForm(false);
        enqueueSnackbar(
          <FormattedMessage
            defaultMessage="Failed to update data. Try again later!"
            id="Failed_to_update_data._Try_again_later!" />,
          {
            variant: "error",
          }
        );
      });
  };

  useEffect(() => {
    setFetchingData(true);
    getForm()
      .then(({ data }) => {
        setData(data);
        setFetchingData(false);
        enqueueSnackbar(<FormattedMessage defaultMessage="Data loaded!"/>, {
          variant: "success",
        });
      })
      .catch((err) => {
        if (err?.response?.status === 404) {
          enqueueSnackbar(
            <FormattedMessage defaultMessage="Missing data for account!"/>,
            {
              variant: "warning",
            }
          );
        } else {
          enqueueSnackbar(
            <FormattedMessage defaultMessage="Fetch data failed!"/>,
            {
              variant: "error",
            }
          );
        }

        setFetchingData(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isFetchingData) return <FullPageLoader />;

  if (!data)
    return <FormattedMessage defaultMessage="Missing data for account!"/>;

  return (
    <EditSurvey
      questionsFormProps={{
        onSubmit: handleSubmitQuestions,
        isLoading: isUpdatingForm,
        questionsData: data.questions,
        optionsData: data.options,
        isLocked: data.isLocked,
      }}
      colorsFormProps={{
        onSubmit: handleColorsPage,
        isLoading: isUpdatingForm,
        settings: data.settings,
        isLocked: data.isLocked,
      }}
      welcomePageFormProps={{
        onSubmit: handleSubmitWelcomePage,
        isLoading: isUpdatingForm,
        welcomePageString: data.welcomePageString,
        isLocked: data.isLocked,
      }}
      welcomePageFormProps2={{
        onSubmit: handleSubmitWelcomePage2,
        isLoading: isUpdatingForm,
        welcomePageString: data.welcomePageString2,
        isLocked: data.isLocked,
      }}
    />
  );
};

export default UserWrapperForm;

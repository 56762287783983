import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Grid } from "@material-ui/core";
import styled from "styled-components";

import { EditorState, ContentState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { FormattedMessage } from "react-intl";

const Spacer = styled.div`
  margin-top: 40px;
`;

const DraftEditor = ({ isLoading, onSubmit, welcomePageString, isLocked }) => {
  const getEditorStateFromHtml = html => {
    const contentBlock = htmlToDraft(html);
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks
    );
    const editorState = EditorState.createWithContent(contentState);
    return editorState;
  };

  const [editorState, setEditorState] = useState(
    getEditorStateFromHtml(welcomePageString)
  );

  const handleSubmit = () => {
    const rawHTMLCode = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );

    onSubmit(rawHTMLCode);
  };

  return (
    (<Grid container justify="center">
      <Grid item xs={12}>
        <h3>
          <FormattedMessage defaultMessage="Welcome page:"/>
        </h3>
        <Editor
          editorState={editorState}
          toolbarClassName="toolbarClassName"
          editorClassName="editorClassName"
          onEditorStateChange={setEditorState}
          readOnly={isLocked}
        />
        <Spacer />
        {!isLocked && (
          <Button
            variant="contained"
            color="primary"
            fullWidth
            size="large"
            disabled={isLoading}
            onClick={handleSubmit}
          >
            {isLoading ? (
              <FormattedMessage defaultMessage="Saving..."/>
            ) : (
              <FormattedMessage defaultMessage="Save"/>
            )}
          </Button>
        )}
      </Grid>
    </Grid>)
  );
};

DraftEditor.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isLocked: PropTypes.bool.isRequired,
  welcomePageString: PropTypes.string.isRequired
};

export default DraftEditor;

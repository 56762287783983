import React from "react";
import { useHistory } from "react-router";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Fab from "@material-ui/core/Fab";

import RoutePaths from "enums/routes";

const BackButton = () => {
  const history = useHistory();
  return (
    <Fab
      color="secondary"
      size="small"
      aria-label="add"
      onClick={() => history.push(RoutePaths.MANAGE_FORMS)}
    >
      <ArrowBackIcon />
    </Fab>
  );
};

export default BackButton;

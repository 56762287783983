import React from "react";
import { Route, Redirect } from "react-router-dom";
import { isUserLogged } from "utils/Auth";
import RoutePaths from "enums/routes";

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        isUserLogged() ? <Component {...props} /> : <Redirect to={RoutePaths.LOGIN} />
      }
    />
  );
};

export default PrivateRoute;

import React from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { Container, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { useHistory } from "react-router";
import { removeSessionToken } from "utils/Auth";
import { useSnackbar } from "notistack";
import { FormattedMessage } from "react-intl";

import Sidebar from "components/Sidebar";
import { isUserLogged } from "utils/Auth";
import RoutePaths from "enums/routes";
import logo from "images/logo-full.png";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  logoutButtonWrapper: {
    textAlign: "right",
    width: "100%",
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(4),
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "#fff",
  },
  toolbar: theme.mixins.toolbar,
}));

const Layout = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const history = useHistory();

  const signOut = () => {
    removeSessionToken();
    enqueueSnackbar(<FormattedMessage defaultMessage="Logged out!" />, {
      variant: "success",
    });
    history.push(RoutePaths.LOGIN);
  };

  return (<>
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <img src={logo} alt="logo" style={{ maxHeight: 39 }} /> // so the new logo is in line in the surveyadmin

          {isUserLogged() && (
            <div className={classes.logoutButtonWrapper}>
              <Button onClick={signOut}>
                <FormattedMessage defaultMessage="Logout" />
              </Button>
            </div>
          )}
        </Toolbar>
      </AppBar>
      {isUserLogged() && <Sidebar />}
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Container>{children}</Container>
      </main>
    </div>
  </>);
};

Layout.propTypes = {
  children: PropTypes.any,
};

export default Layout;

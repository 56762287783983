import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";

import {
  getDefaultEngForm,
  getDefaultEspForm,
  getDefaultBrForm,
  getDefaultItForm,
} from "components/ManageForms/utils";

const availableFormIds = [...Array(30).keys()].map((i) => i + 1);
const languages = ["en", "es", "br", "it"];

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: "100%",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddFormModal = ({ onSubmit, onClose, isLoading, allFormsIds }) => {
  const classes = useStyles();
  const uniqueValues = availableFormIds.filter(
    (item) => !allFormsIds.includes(item)
  );
  const [selectedFormId, setSelectedFormId] = useState(uniqueValues[0]);
  const [formLang, setFormLang] = useState("en");
  const [formTitle, setFormTitle] = useState("");
  const [error, setError] = useState("");

  const handleSelectFormId = ({ target }) => setSelectedFormId(target.value);

  const handleSelectFormLang = ({ target }) => setFormLang(target.value);

  const handleNameChange = ({ target }) => setFormTitle(target.value);

  const handleSubmit = () => {
    let form = {};
    setError("");
    if (!formTitle) {
      setError(<FormattedMessage defaultMessage="Title is required!"/>);
      return;
    }
    if (formLang === "en") {
      form = getDefaultEngForm(selectedFormId, formTitle);
    }
    if (formLang === "es") {
      form = getDefaultEspForm(selectedFormId, formTitle);
    }
    if (formLang === "br") {
      form = getDefaultBrForm(selectedFormId, formTitle);
    }
    if (formLang === "it") {
      form = getDefaultItForm(selectedFormId, formTitle);
    }
    onSubmit(form);
  };

  return (
    (<Dialog
      open
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">
        <FormattedMessage defaultMessage="Add new form"/>
      </DialogTitle>
      <DialogContent style={{ minWidth: "600px" }}>
        <FormControl className={classes.formControl}>
          <InputLabel>
            <FormattedMessage defaultMessage="Select form"/>
          </InputLabel>
          <Select value={selectedFormId} onChange={handleSelectFormId}>
            {uniqueValues.map((form, index) => (
              <MenuItem value={form} key={index}>
                {form}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel>
            <FormattedMessage defaultMessage="Select language"/>
          </InputLabel>
          <Select value={formLang} onChange={handleSelectFormLang}>
            {languages.map((lang, index) => (
              <MenuItem value={lang} key={index}>
                {lang}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            error={!!error}
            helperText={error}
            value={formTitle}
            label={<FormattedMessage defaultMessage="Form name"/>}
            onChange={handleNameChange}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" disabled={isLoading}>
          <FormattedMessage defaultMessage="Close"/>
        </Button>
        <Button onClick={handleSubmit} color="secondary" disabled={isLoading}>
          {isLoading ? (
            <CircularProgress size={20} color="secondary" />
          ) : (
            <FormattedMessage defaultMessage="Create"/>
          )}
        </Button>
      </DialogActions>
    </Dialog>)
  );
};

AddFormModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default AddFormModal;

import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { useSnackbar } from "notistack";
import styled from "styled-components";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import { FormattedMessage } from "react-intl";

import RoutePaths from "enums/routes";
import FullPageLoader from "components/FullPageLoader";
import ManageUsers from "components/ManageUsers";
import Modal from "components/Modal";
import AddUserModal from "components/ManageUsers/AddUserModal";
import { isAdmin } from "utils/Auth";
import { getAllUsers, removeUserByLogin, registerNewUser } from "utils/api";

const ActionWrapper = styled.div`
  position: absolute;
  bottom: 50px;
  right: 50px;
`;

const ManageUsersPage = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [isFetching, setFetching] = useState(false);
  const [isRemovingUser, setRemovingUser] = useState(false);
  const [removeUserId, setRemoveUserId] = useState(null);
  const [users, setUsers] = useState([]);

  const [isAddUserVisible, setAddUserVisible] = useState(false);
  const [isCreatingUser, setIsCreatingUser] = useState(false);

  useEffect(() => {
    setFetching(true);
    getAllUsers()
      .then(({ data }) => {
        setFetching(false);
        setUsers(data);
        enqueueSnackbar(
          <FormattedMessage defaultMessage="Users data loaded!"/>,
          {
            variant: "success"
          }
        );
      })
      .catch(error => {
        if (error?.response?.status === 401) {
          enqueueSnackbar(
            <FormattedMessage
              defaultMessage="You are unauthorized to see this page!"
              id="You_are_unauthorized_to_see_this_page!" />,
            {
              variant: "warning"
            }
          );
        } else {
          enqueueSnackbar(
            <FormattedMessage defaultMessage="Something went wrong!"/>,
            {
              variant: "error"
            }
          );
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRemoveUser = () => {
    setRemovingUser(true);
    removeUserByLogin(removeUserId)
      .then(response => {
        const filteredUsers = users.filter(user => user.login !== removeUserId);
        setUsers(filteredUsers);
        setRemovingUser(false);
        setRemoveUserId(null);
        enqueueSnackbar(
          <FormattedMessage defaultMessage="Users was removed!"/>,
          {
            variant: "success"
          }
        );
      })
      .catch(error => {
        setRemovingUser(false);
        setRemoveUserId(null);
        enqueueSnackbar(
          <FormattedMessage defaultMessage="Something went wrong!"/>,
          {
            variant: "error"
          }
        );
      });
  };

  const handleAddNewUser = values => {
    setIsCreatingUser(true);
    registerNewUser(values)
      .then(resp => {
        const newUsers = [...users, values];
        setIsCreatingUser(false);
        setAddUserVisible(false);
        enqueueSnackbar(
          <FormattedMessage defaultMessage="User was created!"/>,
          {
            variant: "success"
          }
        );
        setUsers(newUsers);
      })
      .catch(error => {
        if (error?.response?.status === 409) {
          enqueueSnackbar(
            <FormattedMessage defaultMessage="User already exist!"/>,
            {
              variant: "warning"
            }
          );
        } else {
          enqueueSnackbar(
            <FormattedMessage defaultMessage="Something went wrong!"/>,
            {
              variant: "error"
            }
          );
        }
        setIsCreatingUser(false);
        setAddUserVisible(false);
      });
  };

  if (!isAdmin()) {
    return <Redirect to={RoutePaths.EDIT_SURVEY} />;
  }

  if (isFetching) return <FullPageLoader />;
  if (!users) return <FormattedMessage defaultMessage="No users found!"/>;

  return (<>
    <ManageUsers users={users} onRemoveUser={setRemoveUserId} />
    <ActionWrapper>
      <Fab
        color="primary"
        aria-label="add"
        onClick={() => setAddUserVisible(true)}
      >
        <AddIcon />
      </Fab>
    </ActionWrapper>
    {removeUserId && (
      <Modal
        title={<FormattedMessage defaultMessage="Remove user"/>}
        description={`Do you really want to remove ${removeUserId}`}
        isLoading={isRemovingUser}
        onSubmit={handleRemoveUser}
        onClose={() => setRemoveUserId(null)}
      />
    )}
    {isAddUserVisible && (
      <AddUserModal
        isLoading={isCreatingUser}
        onSubmit={handleAddNewUser}
        onClose={() => setAddUserVisible(null)}
      />
    )}
  </>);
};

export default ManageUsersPage;

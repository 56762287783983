import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import SendIcon from "@material-ui/icons/Send";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import BlockIcon from "@material-ui/icons/Block";
import CheckIcon from "@material-ui/icons/Check";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from "@material-ui/core/Tooltip";
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import { FormattedMessage } from "react-intl";

import RoutePaths from "enums/routes";

const useStyles = makeStyles(theme => ({
  list: {
    backgroundColor: theme.palette.background.paper,
    margin: 5
  }
}));

const ManageForms = ({ forms, onRemoveForm, onClickLockForm, onSendForm, onClickBlockForm, onClickSSOType }) => {
  const classes = useStyles();
  const history = useHistory();

  const handleNavigateToForm = formId =>
    history.push(RoutePaths.EDIT_SURVEY, { formId });

  const handleNavigateToUploadUsers = formId =>
    history.push(RoutePaths.UPLOAD_USERS, { formId });

  const handleNavigateToInvitations = formId =>
    history.push(RoutePaths.FORM_INVITATIONS, { formId });

  return (
    (<Grid container spacing={5} justify="center">
      <h1 style={{ textAlign: "center" }}>
        <FormattedMessage defaultMessage="All forms"/>
      </h1>
      <Grid item xs={12}>
        {forms.map(form => (
          <div className={classes.list} key={form._id}>
            <List>
              <ListItem>
                <Grid container spacing={5} justify="center">
                  <Grid item xs={6} style={{ display: "flex" }}>
                    <ListItemAvatar>
                      <Avatar>
                        <AccountCircleIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={form.name}
                      secondary={`FormID: ${form.id}`}
                    />
                  </Grid>
                  <Grid item xs={6} style={{ textAlign: "right" }}>
                    <Tooltip
                      title={<FormattedMessage defaultMessage="Edit form"/>}
                    >
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => handleNavigateToForm(form.id)}
                      >
                        <EditIcon style={{ fill: "#43A047" }} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      title={
                        <FormattedMessage defaultMessage="Check invitations"/>
                      }
                    >
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => handleNavigateToInvitations(form.id)}
                      >
                        <SyncAltIcon style={{ fill: "#f2a307" }} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      title={<FormattedMessage defaultMessage="Upload users"/>}
                    >
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => handleNavigateToUploadUsers(form.id)}
                      >
                        <GroupAddIcon style={{ fill: "#2a47ea" }} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      title={
                        <FormattedMessage defaultMessage="Add recipients to queue"/>
                      }
                    >
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => onSendForm(form.id)}
                      >
                        <SendIcon style={{ fill: "#7ACCBA" }} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      title={
                        <FormattedMessage defaultMessage="Block/Unblock form"/>
                      }
                    >
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => onClickBlockForm(form.id)}
                      >
                        {form.isBlocked ? (
                          <BlockIcon color="error" />
                        ) : (
                          <CheckIcon color="primary" />
                        )}
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      title={
                        <FormattedMessage defaultMessage="Lock/Unlock form"/>
                      }
                    >
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => onClickLockForm(form.id)}
                      >
                        {form.isLocked ? (
                          <LockIcon color="error" />
                        ) : (
                          <LockOpenIcon color="primary" />
                        )}
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      title={
                        <FormattedMessage defaultMessage="SSO type"/>
                      }
                    >
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => onClickSSOType(form.id)}
                      >
                         <VpnKeyIcon color="primary" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      title={<FormattedMessage defaultMessage="Remove form"/>}
                    >
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => onRemoveForm(form.id)}
                      >
                        <DeleteIcon color="error" />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </ListItem>
            </List>
          </div>
        ))}
      </Grid>
    </Grid>)
  );
};

ManageForms.propTypes = {
  forms: PropTypes.array,
  onRemoveForm: PropTypes.func.isRequired,
  onClickLockForm: PropTypes.func.isRequired,
  onSendForm: PropTypes.func.isRequired,
  onClickBlockForm: PropTypes.func.isRequired,
  onClickSSOType: PropTypes.func.isRequired,
};

export default ManageForms;

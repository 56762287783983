import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import { FormattedMessage } from "react-intl";
import Switch from "@material-ui/core/Switch";

const SSO_TYPES = [
  {
    value: 1,
    type: "Google",
  },
];

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: "100%",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SetSSOTypeModal = ({ onSubmit, onClose, isLoading, ssoType }) => {
  const classes = useStyles();
  const [isEnabled, setEnabled] = useState(!!ssoType);
  const [ssoTypeState, setSSOType] = useState(ssoType || 1);

  const handleSubmit = () => {
    onSubmit(isEnabled ? ssoTypeState : 0);
  };

  return (
    (<Dialog
      open
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">
        <FormattedMessage defaultMessage="Set SSO type"/>
      </DialogTitle>
      <DialogContent style={{ minWidth: "600px" }}>
        <DialogContentText id="alert-dialog-slide-description">
          <FormattedMessage defaultMessage="Is enabled:"/>
        </DialogContentText>
        <FormControl className={classes.formControl}>
          <Switch
            checked={isEnabled}
            onChange={(e) => setEnabled(e.target.checked)}
          />
        </FormControl>
        <DialogContentText id="alert-dialog-slide-description">
          <FormattedMessage defaultMessage="Select type of SSO type:"/>
        </DialogContentText>

        <FormControl className={classes.formControl}>
          <Select
            disabled={!isEnabled}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={ssoTypeState}
            onChange={(e) => setSSOType(e.target.value)}
          >
            {SSO_TYPES.map((type, idx) => (
              <MenuItem value={type.value} key={idx}>
                {type.type}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" disabled={isLoading}>
          <FormattedMessage defaultMessage="Close"/>
        </Button>
        <Button onClick={handleSubmit} color="secondary" disabled={isLoading}>
          {isLoading ? (
            <CircularProgress size={20} color="secondary" />
          ) : (
            <FormattedMessage defaultMessage="Save"/>
          )}
        </Button>
      </DialogActions>
    </Dialog>)
  );
};

SetSSOTypeModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  ssoType: PropTypes.number.isRequired,
};

export default SetSSOTypeModal;

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import { useHistory } from "react-router";
import { FormattedMessage } from "react-intl";

import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MailIcon from "@material-ui/icons/Mail";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import AssessmentOutlinedIcon from "@material-ui/icons/AssessmentOutlined";
import GroupIcon from "@material-ui/icons/Group";
import RoutePaths from "enums/routes";
import { isAdmin } from "utils/Auth";
import LanguagePicker from "components/LanguagePicker";

const drawerWidth = 240;

const menuItemsUser = [
  {
    key: "editSurvey",
    translationId: <FormattedMessage defaultMessage="Edit survey" />,
    icon: <MailIcon />,
    link: RoutePaths.EDIT_SURVEY
  },
  {
    key: "reports",
    translationId: <FormattedMessage defaultMessage="Reports" />,
    icon: <AssessmentOutlinedIcon />,
    link: RoutePaths.REPORTS
  }
];

const menuItemsAdmin = [
  {
    key: "manageForms",
    translationId: <FormattedMessage defaultMessage="Manage forms" />,
    icon: <EditOutlinedIcon />,
    link: RoutePaths.MANAGE_FORMS
  },
  {
    key: "manageUsers",
    translationId: <FormattedMessage defaultMessage="Manage users" />,
    icon: <GroupIcon />,
    link: RoutePaths.MANAGE_USERS
  },
  {
    key: "reports",
    translationId: <FormattedMessage defaultMessage="Reports" />,
    icon: <AssessmentOutlinedIcon />,
    link: RoutePaths.REPORTS
  }
  // {
  //   key: "uploadUsers",
  //   translationId: messages.sidebar["upload.users"],
  //   icon: <BackupIcon />,
  //   link: RoutePaths.UPLOAD_USERS
  // },
  // {
  //   key: "formInvitations",
  //   translationId: messages.sidebar["form.invitations"],
  //   icon: <SendIcon />,
  //   link: RoutePaths.FORM_INVITATIONS
  // }
];

const useStyles = makeStyles(theme => ({
  drawer: {
    flexShrink: 0,
    [theme.breakpoints.up("xs")]: {
      width: 55
    },
    [theme.breakpoints.up("md")]: {
      width: drawerWidth
    }
  },
  drawerPaper: {
    [theme.breakpoints.up("xs")]: {
      width: 55,
      flexShrink: 0,
      marginTop: 64
    },
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      flexShrink: 0,
      marginTop: 64
    }
  }
}));

const Sidebar = () => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper
      }}
      anchor="left"
    >
      <Divider />
      <List>
        {isAdmin()
          ? menuItemsAdmin.map(item => (
              <ListItem
                selected={history?.location?.pathname === item.link}
                button
                key={item.key}
                onClick={() => history.push(item.link)}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.translationId} />
              </ListItem>
            ))
          : menuItemsUser.map(item => (
              <ListItem
                selected={history?.location?.pathname === item.link}
                button
                key={item.key}
                onClick={() => history.push(item.link)}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.translationId} />
              </ListItem>
            ))}
      </List>
      <LanguagePicker />
    </Drawer>
  );
};

export default Sidebar;

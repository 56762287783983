import React from "react";
import PropTypes from "prop-types";
import { IntlProvider } from "react-intl";
import localeData from "i18n/locale/index.js";
import { get as getLanguage } from "utils/language";

const getMessages = locale => {
  return localeData[locale];
};

const LOCALE = getLanguage() || "en"; // TODO from state

const Intl = props => {
  return (
    <IntlProvider locale={LOCALE} messages={getMessages(LOCALE)}>
      {props.children}
    </IntlProvider>
  );
};

Intl.propTypes = {
  children: PropTypes.any.isRequired
};

export default Intl;

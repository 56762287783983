import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel
} from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { Formik, Field, Form } from "formik";
import { TextField } from "formik-material-ui";
import * as Yup from "yup";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import ROLES from "enums/roles";
import { getPlans } from "utils/api";
import Packages from "components/ManageUsers/Packages";

const RegisterSchema = Yup.object().shape({
  login: Yup.string()
    .email(<FormattedMessage defaultMessage="Invalid email"/>)
    .required(<FormattedMessage defaultMessage="*Required field!"/>),
  password: Yup.string()
    .min(2, <FormattedMessage defaultMessage="Too short!"/>)
    .max(100, <FormattedMessage defaultMessage="Too long!"/>)
    .required(<FormattedMessage defaultMessage="*Required field!"/>),
  formId: Yup.number()
    .min(1, <FormattedMessage defaultMessage="Min is 1"/>)
    .required(<FormattedMessage defaultMessage="*Required field!"/>)
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddUserModal = ({ isLoading, onSubmit, onClose }) => {
  const [plans, setPlans] = useState([]);
  const [selectedPlanId, setPlan] = useState("basic");
  const [isFetchingPlans, setFetching] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    setFetching(true);
    setError(false);
    getPlans()
      .then(({ data }) => {
        setPlans(data);
        setFetching(false);
      })
      .catch(() => {
        setFetching(false);
        setError(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    (<Dialog
      open
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">
        <FormattedMessage defaultMessage="Add new User"/>
      </DialogTitle>
      <DialogContent style={{ minWidth: "600px" }}>
        <Formik
          initialValues={{
            login: "",
            password: "",
            formId: 1,
            group: ROLES.ADMIN,
            language: "en"
          }}
          validationSchema={RegisterSchema}
          onSubmit={values => onSubmit({ ...values, planId: selectedPlanId })}
        >
          {({ submitForm, values, setFieldValue }) => (
            <Grid container justify="center">
              <Form style={{ width: "100%", maxWidth: "800px" }}>
                <Grid item xs={12}>
                  <Field
                    component={TextField}
                    name="login"
                    type="email"
                    label={<FormattedMessage defaultMessage="Email"/>}
                    fullWidth
                    variant="outlined"
                    multiline
                    margin="dense"
                    disabled={isLoading}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={TextField}
                    type="password"
                    label={<FormattedMessage defaultMessage="Password"/>}
                    name="password"
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    disabled={isLoading}
                  />
                </Grid>
                <br />
                <Grid container>
                  <Grid item xs={6}>
                    <FormLabel component="legend">
                      <FormattedMessage defaultMessage="Group"/>
                    </FormLabel>
                    <RadioGroup
                      aria-label="group"
                      name="group"
                      value={values.group}
                      onChange={({ target }) =>
                        setFieldValue("group", target.value)
                      }
                    >
                      <FormControlLabel
                        value={ROLES.ADMIN}
                        control={<Radio />}
                        label={<FormattedMessage defaultMessage="Admin"/>}
                      />
                      <FormControlLabel
                        value={ROLES.USER}
                        control={<Radio />}
                        label={<FormattedMessage defaultMessage="User"/>}
                      />
                    </RadioGroup>
                  </Grid>
                  <Grid item xs={6}>
                    <FormLabel component="legend">
                      <FormattedMessage defaultMessage="Language"/>
                    </FormLabel>
                    <RadioGroup
                      aria-label="language"
                      name="language"
                      value={values.language}
                      onChange={({ target }) =>
                        setFieldValue("language", target.value)
                      }
                    >
                      <FormControlLabel
                        value="en"
                        control={<Radio />}
                        label={<FormattedMessage defaultMessage="English"/>}
                      />
                      <FormControlLabel
                        value="es"
                        control={<Radio />}
                        label={<FormattedMessage defaultMessage="Espanol"/>}
                      />
                    </RadioGroup>
                  </Grid>
                </Grid>
                <br />
                <Grid item xs={12}>
                  {values.group === ROLES.USER && (
                    <Field
                      component={TextField}
                      type="number"
                      label={<FormattedMessage defaultMessage="FormId"/>}
                      name="formId"
                      fullWidth
                      variant="outlined"
                      margin="dense"
                      disabled={isLoading}
                    />
                  )}
                </Grid>
                {values.group === ROLES.USER && (
                  <Packages
                    isLoading={isFetchingPlans}
                    plans={plans}
                    hasError={error}
                    setPlan={setPlan}
                    selectedPlanId={selectedPlanId}
                  />
                )}

                <DialogActions>
                  <Button
                    onClick={onClose}
                    color="primary"
                    disabled={isLoading}
                  >
                    <FormattedMessage defaultMessage="Close"/>
                  </Button>
                  <Button
                    color="secondary"
                    disabled={isLoading || isFetchingPlans}
                    onClick={submitForm}
                  >
                    {isLoading ? (
                      <FormattedMessage defaultMessage="Loading..."/>
                    ) : (
                      <FormattedMessage defaultMessage="Add"/>
                    )}
                  </Button>
                </DialogActions>
              </Form>
            </Grid>
          )}
        </Formik>
      </DialogContent>
    </Dialog>)
  );
};

AddUserModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isLoading: PropTypes.bool
};

export default AddUserModal;

import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { FormattedMessage } from "react-intl";

const ErrorMessage = styled.div`
  color: red;
  font-weight: bold;
`;

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: "100%"
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SendFormModal = ({ onSubmit, onClose, isLoading }) => {
  const classes = useStyles();

  const [template, setTemplate] = useState("");
  const [subject, setSubject] = useState("");
  const [error, setError] = useState("");

  const handleNameChange = ({ target }) => {
    setError("");
    setTemplate(target.value);
  };

  const handleSubjectChange = ({ target }) => {
    setError("");
    setSubject(target.value);
  };

  const handleSubmit = () => {
    if (template.length === 0 || subject.length === 0) {
      setError(<FormattedMessage defaultMessage="Fields are required!"/>);
    } else {
      setError();
      onSubmit(template, subject);
    }
  };

  return (
    (<Dialog
      open
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">
        <FormattedMessage defaultMessage="Send form/Add to batch"/>
      </DialogTitle>
      <DialogContent style={{ minWidth: "600px" }}>
        <DialogContentText id="alert-dialog-slide-description">
          <FormattedMessage
            defaultMessage="Type template name (from mailgun!!!)"
            id="Type_template_name_(from_mailgun!!!)" />
        </DialogContentText>

        <FormControl className={classes.formControl}>
          <TextField
            value={template}
            label={<FormattedMessage defaultMessage="Template name"/>}
            onChange={handleNameChange}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            value={subject}
            label={<FormattedMessage defaultMessage="Email subject"/>}
            onChange={handleSubjectChange}
          />
        </FormControl>
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" disabled={isLoading}>
          <FormattedMessage defaultMessage="Close"/>
        </Button>
        <Button
          onClick={handleSubmit}
          color="secondary"
          disabled={isLoading || !!error}
        >
          {isLoading ? (
            <CircularProgress size={20} color="secondary" />
          ) : (
            <FormattedMessage defaultMessage="Send"/>
          )}
        </Button>
      </DialogActions>
    </Dialog>)
  );
};

SendFormModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
};

export default SendFormModal;

import React, { useState, useEffect } from "react";
import { Redirect, useHistory } from "react-router-dom";

import { useSnackbar } from "notistack";
import { Button } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { FormattedMessage } from "react-intl";

import FullPageLoader from "components/FullPageLoader";

import RoutePaths from "enums/routes";
import { isAdmin } from "utils/Auth";
import {
  getAllForms,
  getInvitationCountById,
  cleanInvitationById
} from "utils/api";

import BackButton from "components/BackButton";

const SendFormsPage = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [isFetching, setFetching] = useState(false);
  const [forms, setForms] = useState([]);
  const {
    location: { state }
  } = useHistory();
  const [selectedFormId, setSelectedFormId] = useState(null);
  const [isLoadingInvitations, setLoadingInvitations] = useState(false);
  const [invitationsData, setInvitationsData] = useState(null);

  const [isRemovingInvitations, setRemovingInvitations] = useState(false);

  useEffect(() => {
    setFetching(true);
    getAllForms()
      .then(({ data }) => {
        setFetching(false);
        setForms(data);
        handleChangeForm(state.formId);

        enqueueSnackbar(
          <FormattedMessage defaultMessage="Forms data loaded!"/>,
          {
            variant: "success"
          }
        );
      })
      .catch(error => {
        if (error?.response?.status === 401) {
          enqueueSnackbar(
            <FormattedMessage
              defaultMessage="You are unauthorized to see this page!"
              id="You_are_unauthorized_to_see_this_page!" />,
            {
              variant: "warning"
            }
          );
        } else {
          enqueueSnackbar(
            <FormattedMessage defaultMessage="Something went wrong!"/>,
            {
              variant: "error"
            }
          );
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFetchInvitations = formId => {
    setLoadingInvitations(true);
    getInvitationCountById(formId)
      .then(({ data }) => {
        setLoadingInvitations(false);
        setInvitationsData(data.count);
        enqueueSnackbar(
          <FormattedMessage defaultMessage="Invitations loaded!"/>,
          {
            variant: "success"
          }
        );
      })

      .catch(error => {
        setLoadingInvitations(false);
        enqueueSnackbar(<FormattedMessage defaultMessage="Load failed!"/>, {
          variant: "error"
        });
      });
  };

  const handleChangeForm = formId => {
    setSelectedFormId(formId);
    handleFetchInvitations(formId);
  };

  const handleRemoveInvitations = () => {
    setRemovingInvitations(true);
    cleanInvitationById(selectedFormId)
      .then(response => {
        setRemovingInvitations(false);
        setInvitationsData(0);
        enqueueSnackbar(
          <FormattedMessage defaultMessage="Invitations were cleaned!"/>,
          {
            variant: "success"
          }
        );
      })
      .catch(error => {
        setRemovingInvitations(false);
        enqueueSnackbar(<FormattedMessage defaultMessage="Request failed!"/>, {
          variant: "error"
        });
      });
  };

  const renderInvitations = () => {
    if (isRemovingInvitations || invitationsData || invitationsData === 0) {
      return (
        (<div>
          <h2>
            <FormattedMessage defaultMessage="Need to send"/>:{" "}
            {invitationsData}
          </h2>
          <Button
            color="primary"
            variant="contained"
            onClick={() => handleFetchInvitations(selectedFormId)}
          >
            <FormattedMessage defaultMessage="Refresh"/>
          </Button>
          <h2>
            <FormattedMessage defaultMessage="Clean invitations"/>
          </h2>
          <Button
            color="secondary"
            variant="contained"
            onClick={handleRemoveInvitations}
          >
            {isRemovingInvitations ? (
              <CircularProgress size={24} />
            ) : (
              <FormattedMessage defaultMessage="Clean"/>
            )}
          </Button>
        </div>)
      );
    }
  };

  if (!isAdmin() || !forms || !state?.formId) {
    return <Redirect to={RoutePaths.MANAGE_FORMS} />;
  }

  if (isFetching) return <FullPageLoader />;

  return (
    <>
      <BackButton />
      <h1>Selected form: {state?.formId}</h1>
      {isLoadingInvitations ? <CircularProgress /> : renderInvitations()}
    </>
  );
};

export default SendFormsPage;

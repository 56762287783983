import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

import SendIcon from "@material-ui/icons/Send";
import DeleteIcon from "@material-ui/icons/Delete";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import EditIcon from "@material-ui/icons/Edit";
import { FormattedMessage } from "react-intl";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Wrapper = styled.div`
  display: flex;
  padding: 10px;
  width: 100%;
  align-items: center;
  justify-content: left;
`;
const InfoModal = ({ onClose }) => {
  return (
    (<Dialog
      open
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">
        <FormattedMessage defaultMessage="Icons funcionality"/>
      </DialogTitle>
      <DialogContent style={{ minWidth: "600px" }}>
        <Wrapper>
          <EditIcon style={{ fill: "#43A047" }} /> &nbsp;&nbsp;
          <FormattedMessage
            defaultMessage="Edit form (Welcome page, colors, questions...)"
            id="Edit_form_(Welcome_page__colors__questions...)" />
        </Wrapper>
        <Wrapper>
          <SyncAltIcon style={{ fill: "#f2a307" }} /> &nbsp;&nbsp;
          <FormattedMessage defaultMessage="List of emails to send"/>
        </Wrapper>
        <Wrapper>
          <GroupAddIcon style={{ fill: "#2a47ea" }} /> &nbsp;&nbsp;
          <FormattedMessage
            defaultMessage="Upload/Remove recipients for form"
            id="Upload/Remove_recipients_for_form" />
        </Wrapper>
        <Wrapper>
          <SendIcon style={{ fill: "#7ACCBA" }} />
          &nbsp;&nbsp;
          <FormattedMessage
            defaultMessage="Add recipients to queue (handled by CRON)"
            id="Add_recipients_to_queue_(handled_by_CRON)" />
        </Wrapper>
        <Wrapper>
          <LockIcon color="error" /> &nbsp;&nbsp;
          <FormattedMessage defaultMessage="Form is locked for users"/>
        </Wrapper>
        <Wrapper>
          <LockOpenIcon color="primary" /> &nbsp;&nbsp;
          <FormattedMessage
            defaultMessage="Form is unlocked for users"
            id="Form_is_unlocked_for_users" />
        </Wrapper>
        <Wrapper>
          <DeleteIcon color="error" /> &nbsp;&nbsp;
          <FormattedMessage defaultMessage="Remove form from database"/>
        </Wrapper>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          <FormattedMessage defaultMessage="Close"/>
        </Button>
      </DialogActions>
    </Dialog>)
  );
};

InfoModal.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default InfoModal;

import React, { Component } from "react";

import Flags from "react-flags-select";
import "react-flags-select/css/react-flags-select.css";
import "components/styles.css";
import { get as getLanguage, set as setLanguage } from "utils/language";

const DEFAULT_LANGUAGE = "US";

const LANGUAGE_MAPPING = {
  ES: "es",
  US: "en",
  BR: "br",
  IT: "it"
};

const LANGUAGE_MAPPING_REVERSE = {
  es: "ES",
  en: "US",
  br: "BR",
  it: "IT"
};

export default class LanguagePicker extends Component {
  render() {
    const language = getLanguage();
    return (
      <div style={{ position: "fixed", bottom: 0 }}>
        <Flags
          defaultCountry={
            language ? LANGUAGE_MAPPING_REVERSE[language] : DEFAULT_LANGUAGE
          }
          countries={["ES", "US", "BR", "IT"]}
          customLabels={{ US: "English", ES: "Español", BR: "Portuguese", IT: 'Italian' }}
          showSelectedLabel={false}
          // showOptionLabel={false}
          onSelect={language => {
            setLanguage(LANGUAGE_MAPPING[language]);
            window.location.reload();
          }}
        />
      </div>
    );
  }
}

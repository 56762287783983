import React, { useState } from "react";
import { Button, Grid, Paper, MenuItem } from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Close";

import { Formik, Field, Form } from "formik";
import { TextField } from "formik-material-ui";
import * as Yup from "yup";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { getUserPlan, isAdmin } from "utils/Auth";

import CSVReader from "components/EditSurvey/questions/CSVReader";

const Wrapper = styled(Paper)`
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 20px;
  position: relative;
`;

const getQuestionTitleIdFormik = (id) => `question-title-${id}`;
const getQuestionNameIdFormik = (id) => `question-name-${id}`;
const getQuestionTypeIdFormik = (id) => `question-type-${id}`;

// TODO: move to data dir along with files from question form

const selectTypes = {
  MULTI: "multiSelect",
  SINGLE: "singleSelect",
  SINGLE_WITH_OPTION: "singleSelectWithOption",
  COMPANY_VALUES: 'companyValues',
  LIKERT_SCALE: 'likertScale'
};

const EditForm = ({ isLoading, onSubmit, questionsData, optionsData, isLocked }) => {
  const initialValues = {};
  const validationObject = {};

  const [data, setData] = useState(questionsData);

  data.map((question) => {
    const questionTitleId = getQuestionTitleIdFormik(question.id);
    const questionNameId = getQuestionNameIdFormik(question.id);
    const questionTypeId = getQuestionTypeIdFormik(question.id);

    initialValues[questionTitleId] = question.title;
    initialValues[questionNameId] = question.name;
    initialValues[questionTypeId] = question.type;

    initialValues.options =
      optionsData.length !== 0 ? optionsData : null;

    validationObject[questionTitleId] = Yup.string()
      .min(10, <FormattedMessage defaultMessage="Too short!"/>)
      .max(500, <FormattedMessage defaultMessage="Too long!"/>)
      .required(<FormattedMessage defaultMessage="*Required field!"/>);

    validationObject[questionNameId] = Yup.string()
      .min(10, <FormattedMessage defaultMessage="Too short!"/>)
      .max(500, <FormattedMessage defaultMessage="Too long!"/>)
      .required(<FormattedMessage defaultMessage="*Required field!"/>);

    validationObject.options = Yup.mixed().required(
      <FormattedMessage defaultMessage="*Required field!"/>
    );
    return null;
  });

  const EditSurveyScheme = Yup.object().shape(validationObject);

  return (
    (<Grid container justify="center">
      <Grid item xs={12}>
        <h3>
          <FormattedMessage defaultMessage="Questions:"/>
        </h3>
      </Grid>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          const questions = [];
          data.map((item) => {
            const questionNameId = getQuestionNameIdFormik(item.id);
            const questionTitleId = getQuestionTitleIdFormik(item.id);
            const questionTypeId = getQuestionTypeIdFormik(item.id);
            questions.push({
              ...item,
              name: values[questionNameId],
              title: values[questionTitleId],
              type: values[questionTypeId],
            });
            return null;
          });
          onSubmit({ questions, options: values.options });
        }}
        validationSchema={EditSurveyScheme}
      >
        {({ submitForm, values, dirty, errors, touched, setFieldValue }) => (
          <Grid container justify="center">
            <Form style={{ width: "100%" }}>
              {data.map((question) => {
                return (
                  (<Grid item xs={12} key={question.name}>
                    <Wrapper>
                      <p>
                        <b>
                          <FormattedMessage defaultMessage="Title" />:
                        </b>
                      </p>
                      <Field
                        component={TextField}
                        name={getQuestionTitleIdFormik(question.id)}
                        type="text"
                        fullWidth
                        multiline
                        margin="dense"
                        disabled={isLoading || isLocked}
                        color="secondary"
                      />
                      <p>
                        <b>
                          <FormattedMessage defaultMessage="Description" />:
                        </b>
                      </p>
                      <Field
                        component={TextField}
                        name={getQuestionNameIdFormik(question.id)}
                        type="text"
                        fullWidth
                        variant="outlined"
                        multiline
                        margin="dense"
                        disabled={isLoading || isLocked}
                        color="secondary"
                      />
                      <p>
                        <b>Type:</b>
                      </p>
                      <Field
                        component={TextField}
                        name={getQuestionTypeIdFormik(question.id)}
                        disabled={isLoading || isLocked}
                        // fullWidth
                        color="secondary"
                        type="text"
                        select={true}
                        value={values[getQuestionTypeIdFormik(question.id)]}
                      >
                        <MenuItem value={selectTypes.MULTI}>
                          Multi-select
                        </MenuItem>
                        <MenuItem value={selectTypes.SINGLE}>
                          Single-select
                        </MenuItem>
                        <MenuItem value={selectTypes.SINGLE_WITH_OPTION}>
                          Single-select with options
                        </MenuItem>
                        <MenuItem value={selectTypes.COMPANY_VALUES}>
                          Company values
                        </MenuItem>
                        <MenuItem value={selectTypes.LIKERT_SCALE}>
                          Likert scale
                        </MenuItem>
                      </Field>
                      <Fab
                        size="small"
                        style={{ position: "absolute", top: 0, right: 0 }}
                        color="secondary"
                        aria-label="add"
                        onClick={() => {
                          const filteredData = data.filter(
                            (item) => item.id !== question.id
                          );
                          setData(filteredData);
                        }}
                      >
                        <RemoveIcon />
                      </Fab>
                    </Wrapper>
                  </Grid>)
                );
              })}
              <Fab
                color="primary"
                aria-label="add"
                onClick={() => {
                  const id = data.length + 1;

                  const newData = {
                    id: id,
                    name: "Please update this question's description",
                    options: [],
                    title: "Please update this question’s title",
                    type: selectTypes.SINGLE_WITH_OPTION,
                  };
                  setData([...data, newData]);
                  setFieldValue(getQuestionTypeIdFormik(id), newData.type);
                  setFieldValue(getQuestionNameIdFormik(id), newData.name);
                  setFieldValue(getQuestionTitleIdFormik(id), newData.title);
                }}
              >
                <AddIcon />
              </Fab>

              <CSVReader
                isDisabled={isLocked}
                inputId="csvInput"
                name="options"
                onFileLoaded={(data) => setFieldValue("options", data)}
                error={touched && touched.options && errors && errors.options}
                defaultValue={values.options}

              />
              <br />
              {!isLocked && (
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    size="large"
                    disabled={isLoading || !dirty}
                    onClick={submitForm}
                  >
                    {isLoading ? (
                      <FormattedMessage defaultMessage="Saving..." />
                    ) : (
                      <FormattedMessage defaultMessage="Save" />
                    )}
                  </Button>
                </Grid>
              )}
            </Form>
          </Grid>
        )}
      </Formik>
    </Grid>)
  );
};

EditForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isLocked: PropTypes.bool.isRequired,
  questionsData: PropTypes.array.isRequired,
  optionsData: PropTypes.array.isRequired,
};

export default EditForm;

import RoutePaths from "enums/routes";
import ROLES from "enums/roles";
import jwt from "jwt-decode";

export const getSessionToken = () => localStorage.getItem("SURVEY_ADMIN_TOKEN");
export const setSessionToken = token =>
  localStorage.setItem("SURVEY_ADMIN_TOKEN", token);
export const removeSessionToken = () =>
  localStorage.removeItem("SURVEY_ADMIN_TOKEN");

export const isUserLogged = () => {
  const token = getSessionToken();
  return !!token;
};

export const logout = () => {
  removeSessionToken();
  window.location = RoutePaths.LOGIN;
};

export const isAdmin = defaultToken => {
  const token = getSessionToken();
  const { group } = jwt(defaultToken ? defaultToken : token);

  if (group === ROLES.ADMIN) return true;
  return false;
};

export const getUserLocale = () => {
  const token = getSessionToken();
  if (!token) return "en";
  const decodedToken = jwt(token);
  return decodedToken?.language || "en";
};

export const getUserFormId = () => {
  const token = getSessionToken();
  const decodedToken = jwt(token);

  return decodedToken.formId;
};

export const getUserFormURL = () => {
  const token = getSessionToken();
  const decodedToken = jwt(token);
  return `https://survey.cognitivetalentsolutions.com/?email=${decodedToken.login}&formId=${decodedToken.formId}&hash=${decodedToken.hash}`;
};

export const getUserPlan = () => {
  const token = getSessionToken();
  const decodedToken = jwt(token);
  return decodedToken?.plan;
};

export default {
  logout,
  getSessionToken,
  setSessionToken,
  removeSessionToken,
  isUserLogged,
  isAdmin,
  getUserFormId,
  getUserFormURL,
  getUserPlan
};

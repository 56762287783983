import React from "react";
import Chart from "react-apexcharts";
import { Grid } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";

import NoData from "components/Reports/NoData";

const options = {
  labels: ["Total responses", "Not responded"],
  plotOptions: {
    pie: {
      donut: {
        labels: {
          show: true
        }
      }
    }
  },
  legend: {
    show: true,
    position: "bottom"
  }
};

const style = { margin: "0 10px", height: "auto", padding: "10px" };

const ReportsComponent = ({ data }) => {
  const recipients = data?.recipientsCount;
  const answers = data?.answersCount;

  return !recipients ? (
    <Grid container justify="center">
      <NoData />
    </Grid>
  ) : (
    <Grid container justify="center" style={{ textAlign: "center" }}>
      <Grid item xs={12}>
        <Chip
          color="primary"
          size="medium"
          label={`Answers: ${answers}`}
          style={style}
        />
        <Chip
          style={style}
          color="secondary"
          size="medium"
          label={`Recipients: ${recipients}`}
        />
        <Chip
          style={style}
          color="primary"
          variant="outlined"
          size="medium"
          label={`Answers (%): ${Math.round((100 * answers) / recipients)}%`}
        />
      </Grid>
      <br />
      <br />
      <br />
      <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
        <Chart
          options={options}
          series={[answers, recipients - answers]}
          type="donut"
          width={380}
        />
      </Grid>
    </Grid>
  );
};

export default ReportsComponent;

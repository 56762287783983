import React from "react";
import styled from "styled-components";
import { Paper, Grid, Divider } from "@material-ui/core";

const Wrapper = styled(Paper)`
  text-align: center;
  background-color: ${props =>
    props.isActive ? "rgba(201, 197, 197, 0.4)" : "#fff"};
  border: 2px solid ${props => (props.isActive ? "#2790C8" : "silver")};
  cursor: pointer;
`;

const Title = styled.h3`
  text-transform: capitalize;
`;

const Description = styled.div`
  padding: 10px;
  font-size: 16px;
`;

const Packages = ({ plans, isLoading, hasError, setPlan, selectedPlanId }) => {
  if (hasError) return "Failed to load packages!";

  if (isLoading) return "Loading!!";

  return (
    <>
      <h2>Plans:</h2>
      <Grid container justify="center" spacing={4}>
        {plans.map(plan => (
          <Grid
            item
            xs={12}
            md={4}
            key={plan.id}
            onClick={() => setPlan(plan.id)}
          >
            <Wrapper isActive={selectedPlanId === plan.id}>
              <Title>{plan.id}</Title>
              <Divider />
              <Description>{plan.maxFormOptionsSize}</Description>
            </Wrapper>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default Packages;

import React, { useState } from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import BackupIcon from "@material-ui/icons/Backup";
import ReactJson from "react-json-view";
import CircularProgress from "@material-ui/core/CircularProgress";
import { FormattedMessage } from "react-intl";

const UploadUsersForm = ({
  formIds,
  onSubmit,
  isLoading,
  onChangeFormId,
  isRemovingUsers,
  onRemoveUsers,
  usersForForm,
  selectedFormId
}) => {
  const [selectedUsers, setSelectedUsers] = useState([]);

  const handleUsersDropped = event => {
    const reader = new FileReader();
    reader.onload = function(event) {
      const users = JSON.parse(event.target.result);
      const parsedUsers = users.map(user => ({
        ...user,
        formId: selectedFormId
      }));
      setSelectedUsers(parsedUsers);
    };
    reader.readAsText(event?.target?.files[0]);
    event.target.value = null;
  };

  const handleSubmit = () => {
    onSubmit({
      formId: selectedFormId,
      users: selectedUsers
    });
  };

  return (
    (<Grid>
      <h1>Selected form: {selectedFormId}</h1>
      <>
        <h3>Upload users:</h3>
        <input
          color="primary"
          accept=".json"
          type="file"
          onChange={handleUsersDropped}
          id="icon-button-file"
          style={{ display: "none" }}
          disabled={!selectedFormId}
        />
        <label htmlFor="icon-button-file">
          <Button
            disabled={!selectedFormId}
            variant="contained"
            component="span"
            size="large"
            color="primary"
          >
            <BackupIcon />
          </Button>
        </label>
      </>
      <ReactJson
        src={selectedUsers.length !== 0 ? selectedUsers : usersForForm}
        collapsed
        displayDataTypes={false}
        name={false}
      />
      <br />
      <Button
        onClick={onRemoveUsers}
        disabled={
          isRemovingUsers || !selectedFormId || usersForForm.length === 0
        }
        color="secondary"
        variant="outlined"
      >
        {isRemovingUsers ? (
          <CircularProgress size={24} />
        ) : (
          <FormattedMessage defaultMessage="Remove users"/>
        )}
      </Button>
      <br />
      <br />
      <Button
        onClick={handleSubmit}
        disabled={isLoading || selectedUsers.length === 0}
        variant="contained"
        fullWidth
        size="large"
        color="primary"
      >
        {isLoading ? (
          <CircularProgress size={24} />
        ) : (
          <FormattedMessage defaultMessage="Upload"/>
        )}
      </Button>
    </Grid>)
  );
};

UploadUsersForm.propTypes = {
  formIds: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onChangeFormId: PropTypes.func.isRequired,
  onRemoveUsers: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isRemovingUsers: PropTypes.bool.isRequired,
  usersForForm: PropTypes.array,
  selectedFormId: PropTypes.number.isRequired
};

export default UploadUsersForm;

import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import styled from "styled-components";

const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
`;
const FullPageLoader = () => {
  return (
    <Wrapper>
      <CircularProgress size={50} />
    </Wrapper>
  );
};

export default FullPageLoader;

import axios from "axios";
import { getSessionToken } from "utils/Auth";

axios.interceptors.request.use(function(config) {
  const token = getSessionToken();
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

// get form for login (every user has assigned FormId)
export const getForm = () => axios.get(`${process.env.REACT_APP_API_URL}/form`);

// update form for login ^^
export const updateForm = data =>
  axios.put(`${process.env.REACT_APP_API_URL}/form`, data);

// ADMIN ROUTES

export const createNewForm = formData =>
  axios.post(`${process.env.REACT_APP_API_URL}/form`, formData);

export const registerNewUser = userSchema =>
  axios.post(`${process.env.REACT_APP_API_URL}/register`, userSchema);

export const getAllForms = () =>
  axios.get(`${process.env.REACT_APP_API_URL}/forms`);

export const getAllUsers = () =>
  axios.get(`${process.env.REACT_APP_API_URL}/users`);

export const removeUserByLogin = userLogin =>
  axios.delete(`${process.env.REACT_APP_API_URL}/user/${userLogin}`);

export const removeFormById = formId =>
  axios.delete(`${process.env.REACT_APP_API_URL}/form/${formId}`);

export const updateFormById = (data, formId) =>
  axios.put(`${process.env.REACT_APP_API_URL}/form/${formId}`, data);

export const lockFormById = formId =>
  axios.put(`${process.env.REACT_APP_API_URL}/form/${formId}/lock`);

export const unlockFormById = formId =>
  axios.put(`${process.env.REACT_APP_API_URL}/form/${formId}/unlock`);

  export const blockFormById = formId =>
  axios.put(`${process.env.REACT_APP_API_URL}/form/${formId}/block`);

export const unblockFormById = formId =>
  axios.put(`${process.env.REACT_APP_API_URL}/form/${formId}/unblock`);
  
export const setSSOTypeByFormId = (formId, ssoType) =>
  axios.put(`${process.env.REACT_APP_API_URL}/form/${formId}/ssoType`, { ssoType });

export const getPlans = () =>
  axios.get(`${process.env.REACT_APP_API_URL}/plans`);
// RECIPIENTS

// used for uploadUser page to see if there are some already added
export const getRecipientsByFormId = formId =>
  axios.get(`${process.env.REACT_APP_API_URL}/recipients/${formId}`);

export const deleteRecipientsByFormId = formId =>
  axios.delete(`${process.env.REACT_APP_API_URL}/recipients/${formId}`);

// upload new users to mongo
export const uploadRecipientsByFormId = (formId, data) =>
  axios.post(`${process.env.REACT_APP_API_URL}/recipients/${formId}`, data);

// SENDING INVITATIONS

// Add emails to queue for sending (cron on BE is handling) PARAMS: TemplateName + Subject
export const sendFormById = (formId, templateName, subject) =>
  axios.put(`${process.env.REACT_APP_API_URL}/form/${formId}/send`, {
    templateName,
    subject
  });

// Get info about how many emails are in queue
export const getInvitationCountById = formId =>
  axios.get(
    `${process.env.REACT_APP_API_URL}/invitations/countForForm/${formId}`
  );

// Clean queue by formId
export const cleanInvitationById = formId =>
  axios.delete(
    `${process.env.REACT_APP_API_URL}/invitations/deleteForForm/${formId}`
  );

// REPORTS

export const getReportForFormId = formId =>
  axios.get(`${process.env.REACT_APP_API_URL}/report/forForm/${formId}`);

export default {
  BASE_PATH: "/",
  LOGIN: "/login",
  EDIT_SURVEY: "/edit_survey",
  REPORTS: "/reports",

  /* ADMIN ROUTES */
  MANAGE_USERS: "/manage_users",
  MANAGE_FORMS: "/manage_forms",
  UPLOAD_USERS: "/upload_users",
  FORM_INVITATIONS: "/form_invitations"
};

import React from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import { FormattedMessage } from "react-intl";

import QuestionsForm from "components/EditSurvey/questions";
import ColorForm from "components/EditSurvey/colors";
import WelcomePageForm from "components/EditSurvey/welcomePage";

const TabPanel = ({ children, value, index, ...props }) => (
  <Typography
    component="div"
    role="tabpanel"
    hidden={value !== index}
    id={`full-width-tabpanel-${index}`}
    aria-labelledby={`full-width-tab-${index}`}
    {...props}
  >
    {value === index && <Box p={3}>{children}</Box>}
  </Typography>
);

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

const a11yProps = index => ({
  id: `full-width-tab-${index}`,
  "aria-controls": `full-width-tabpanel-${index}`
});

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: "100%"
  }
}));

const FullWidthTabs = ({
  questionsFormProps,
  colorsFormProps,
  welcomePageFormProps,
  welcomePageFormProps2
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = index => {
    setValue(index);
  };
  const icon = questionsFormProps.isLocked ? (
    <LockIcon color="error" />
  ) : (
    <LockOpenIcon color="primary" />
  );

  return (
    (<div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab
            label={<FormattedMessage defaultMessage="Welcome page"/>}
            icon={icon}
            {...a11yProps(0)}
          />
          <Tab
            label={<FormattedMessage defaultMessage="Second page" />}
            icon={icon}
            {...a11yProps(1)}
          />
          <Tab
            label={<FormattedMessage defaultMessage="Branding"/>}
            icon={icon}
            {...a11yProps(2)}
          />
          <Tab
            label={<FormattedMessage defaultMessage="Questions + Answers"/>}
            icon={icon}
            {...a11yProps(3)}
          />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <WelcomePageForm {...welcomePageFormProps} />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <WelcomePageForm {...welcomePageFormProps2} />
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <ColorForm {...colorsFormProps} />
        </TabPanel>
        <TabPanel value={value} index={3} dir={theme.direction}>
          <QuestionsForm {...questionsFormProps} />
        </TabPanel>
      </SwipeableViews>
    </div>)
  );
};

FullWidthTabs.propTypes = {
  questionsFormProps: PropTypes.shape({
    onSubmit: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    questionsData: PropTypes.array.isRequired,
    optionsData: PropTypes.array.isRequired,
    isLocked: PropTypes.bool.isRequired
  }).isRequired,
  colorsFormProps: PropTypes.shape({
    onSubmit: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    settings: PropTypes.object.isRequired,
    isLocked: PropTypes.bool.isRequired
  }).isRequired,
  welcomePageFormProps: PropTypes.shape({
    onSubmit: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    welcomePageString: PropTypes.string.isRequired,
    isLocked: PropTypes.bool.isRequired
  }).isRequired,
  welcomePageFormProps2: PropTypes.shape({
    onSubmit: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    welcomePageString: PropTypes.string.isRequired,
    isLocked: PropTypes.bool.isRequired
  }).isRequired
};

export default FullWidthTabs;

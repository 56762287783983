import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import { FormattedMessage } from "react-intl";

import USER_ROLES from "enums/roles";

const useStyles = makeStyles(theme => ({
  list: {
    backgroundColor: theme.palette.background.paper,
    margin: 5
  }
}));

const isAdmin = group => USER_ROLES.ADMIN === group;

const ManageUsers = ({ users, onRemoveUser }) => {
  const classes = useStyles();

  return (
    (<Grid container spacing={5} justify="center">
      <h1 style={{ textAlign: "center" }}><FormattedMessage defaultMessage="All Users"/></h1>
      <Grid item xs={12}>
        {users.map(user => (
          <div className={classes.list} key={user._id}>
            <List>
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <AccountCircleIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={user.login}
                  secondary={
                    isAdmin(user.group)
                      ? "Admin"
                      : `FormID: ${user.formId}, Account type: ${user.group}`
                  }
                />
                {!isAdmin(user.group) && (
                  <ListItemSecondaryAction
                    disabled
                    onClick={() => onRemoveUser(user.login)}
                  >
                    <IconButton edge="end" aria-label="delete">
                      <DeleteIcon color="secondary" />
                    </IconButton>
                  </ListItemSecondaryAction>
                )}
              </ListItem>
            </List>
          </div>
        ))}
      </Grid>
    </Grid>)
  );
};

ManageUsers.propTypes = {
  users: PropTypes.array,
  onRemoveUser: PropTypes.func.isRequired
};

export default ManageUsers;

import React from "react";
import styled from "styled-components";

import { isAdmin, getUserFormURL } from "utils/Auth";
import { FormattedMessage } from "react-intl";

import AdminWrapperForm from "containers/AdminWrapperForm";
import UserWrapperForm from "containers/UserWrapperForm";
import SlideshowIcon from "@material-ui/icons/Slideshow";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #0f648c;
  cursor: pointer;
  margin-bottom: 20px;
  width: 150px;
`;

const EditSurveyPage = () => {
  if (isAdmin()) return <AdminWrapperForm />;

  return (<>
    <Wrapper
      onClick={() => {
        const win = window.open(getUserFormURL(), "_blank");
        win.focus();
      }}
    >
      <FormattedMessage defaultMessage="Visit form:"/>
      <SlideshowIcon style={{ fill: "#0F648C" }} />
    </Wrapper>
    <UserWrapperForm />
  </>);
};

export default EditSurveyPage;

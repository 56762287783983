import React from "react";
import PropTypes from "prop-types";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import CircularProgress from '@material-ui/core/CircularProgress';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Modal = ({ title, description, onSubmit, onClose, isLoading }) => (
  <Dialog
    open
    TransitionComponent={Transition}
    keepMounted
    onClose={onClose}
    aria-labelledby="alert-dialog-slide-title"
    aria-describedby="alert-dialog-slide-description"
  >
    <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
    <DialogContent style={{ minWidth: "400px" }}>
      <DialogContentText id="alert-dialog-slide-description">
        {description}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary" disabled={isLoading}>
        Close
      </Button>
      <Button onClick={onSubmit} color="secondary" disabled={isLoading}>
        {isLoading ? <CircularProgress size={20} color="secondary" /> : "Accept"}
      </Button>
    </DialogActions>
  </Dialog>
);

Modal.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default Modal;

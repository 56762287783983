import React, { useState, useCallback } from "react";
import { Button, Grid, Paper } from "@material-ui/core";
import { ChromePicker } from "react-color";
import { createMuiTheme } from "@material-ui/core/styles";
import { useDropzone } from "react-dropzone";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { ThemeProvider } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";

const MAX_IMAGE_SIZE_BYTES = 1 * 1000 * 1000;

const Wrapper = styled(Paper)`
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 30px;
  min-height: 300px;
`;

const TextWrapper = styled.h3`
  color: ${props => props.color};
`;

const ErrorMessage = styled.div`
  text-align: center;
  margin-top: 20px;
  color: red;
`;

const DropzoneWrapper = styled.div`
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  width: 300px;
  min-height: 100px;
  max-height: 400px;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid silver;
  border-radius: 4px;
  position: relative;
`;

const StyledIcon = styled(CloudUploadIcon)`
  position: absolute;
  font-size: 50px;
  color: silver;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const StyledImage = styled.img`
  width: 100%;
  height: auto;
  max-height: 100%;
`;
const getTheme = (primaryColor, secondaryColor) =>
  createMuiTheme({
    palette: {
      primary: {
        main: primaryColor
      },
      secondary: {
        main: secondaryColor
      }
    }
  });

const ColorForm = ({ isLoading, onSubmit, settings, isLocked }) => {
  const [primaryColor, setPrimaryColor] = useState(settings.primaryColor);
  const [secondaryColor, setSecondaryColor] = useState(settings.secondaryColor);
  const [logoBase64, setLogo] = useState(settings.logoBase64);
  const [imageError, setImageError] = useState();

  const onDrop = useCallback(acceptedFiles => {
    var reader = new FileReader();
    if (acceptedFiles.length === 0) {
      setImageError(<FormattedMessage defaultMessage="Only images!"/>);
      return;
    }
    const imageSize = acceptedFiles[0].size;
    if (imageSize > MAX_IMAGE_SIZE_BYTES) {
      setImageError(
        `Max image size is ${MAX_IMAGE_SIZE_BYTES / 1000 / 1000} Mb`
      );
      return;
    }
    reader.readAsDataURL(acceptedFiles[0]);
    reader.onload = function() {
      setLogo(reader.result);
      setImageError();
    };
    reader.onerror = function(error) {
      setImageError(<FormattedMessage defaultMessage="*Required field!"/>);
    };
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "image/jpeg, image/png"
  });

  const handleSubmit = () => {
    if (!logoBase64) {
      setImageError(<FormattedMessage defaultMessage="*Required field!"/>);
      return;
    }
    onSubmit({
      primaryColor,
      secondaryColor,
      logoBase64
    });
  };

  return (
    (<ThemeProvider theme={getTheme(primaryColor, secondaryColor)}>
      <Grid item xs={12}>
        <h3>
          <FormattedMessage defaultMessage="Upload a logo"/>
        </h3>
        <Wrapper>
          <DropzoneWrapper>
            <div {...getRootProps()} style={{ height: "100%" }}>
              <input
                {...getInputProps()}
                type="file"
                accept="image/*"
                disabled={isLocked}
              />
              {isDragActive ? (
                <p>
                  <FormattedMessage defaultMessage="Drop the files here ..."/>
                </p>
              ) : (
                <StyledImage src={logoBase64} alt="logo" />
              )}
              <StyledIcon fontSize="large" />
            </div>
            <ErrorMessage>{imageError}</ErrorMessage>
          </DropzoneWrapper>
        </Wrapper>
      </Grid>
      <Grid item xs={12}>
        <h3>
          <FormattedMessage defaultMessage="Select colors"/>
        </h3>
        <Wrapper>
          <Grid container spacing={5} justify="center">
            <Grid item xs={12} md={5} lg={3}>
              <TextWrapper color={primaryColor}>
                <FormattedMessage defaultMessage="Primary color"/>
              </TextWrapper>
              <ChromePicker
                color={primaryColor}
                onChangeComplete={color => setPrimaryColor(color.hex)}
              />
            </Grid>
            <Grid item xs={12} md={5} lg={3}>
              <TextWrapper color={secondaryColor}>
                <FormattedMessage defaultMessage="Secondary color"/>
              </TextWrapper>
              <ChromePicker
                color={secondaryColor}
                onChangeComplete={color => setSecondaryColor(color.hex)}
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                size="large"
                disabled={isLoading || isLocked}
                onClick={handleSubmit}
              >
                {isLoading ? (
                  <FormattedMessage defaultMessage="Saving..."/>
                ) : (
                  <FormattedMessage defaultMessage="Save"/>
                )}
              </Button>
            </Grid>
          </Grid>
        </Wrapper>
      </Grid>
    </ThemeProvider>)
  );
};

ColorForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isLocked: PropTypes.bool.isRequired,
  settings: PropTypes.object.isRequired
};

export default ColorForm;

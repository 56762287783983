import React, { useState, useEffect } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { FormattedMessage } from "react-intl";

import FullPageLoader from "components/FullPageLoader";
import UploadUsersForm from "components/UploadUsers";
import BackButton from "components/BackButton";

import RoutePaths from "enums/routes";
import { isAdmin } from "utils/Auth";
import {
  getAllForms,
  uploadRecipientsByFormId,
  getRecipientsByFormId,
  deleteRecipientsByFormId
} from "utils/api";

const UploadUsers = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [isFetching, setFetching] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isRemovingUsers, setRemovingUsers] = useState(false);
  const [forms, setForms] = useState([]);
  const [formId, setFormId] = useState();
  const [usersForForm, setUsersForForm] = useState([]);
  const {
    location: { state }
  } = useHistory();

  useEffect(() => {
    setFetching(true);
    getAllForms()
      .then(({ data }) => {
        setFetching(false);
        setForms(data);
        setFormId(state?.formId);
        enqueueSnackbar(
          <FormattedMessage defaultMessage="Forms data loaded!"/>,
          {
            variant: "success"
          }
        );
      })
      .catch(error => {
        if (error?.response?.status === 401) {
          enqueueSnackbar(
            <FormattedMessage
              defaultMessage="You are unauthorized to see this page!"
              id="You_are_unauthorized_to_see_this_page!" />,
            {
              variant: "warning"
            }
          );
        } else {
          enqueueSnackbar(
            <FormattedMessage defaultMessage="Something went wrong!"/>,
            {
              variant: "error"
            }
          );
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUploadUsers = ({ formId, users }) => {
    setLoading(true);
    uploadRecipientsByFormId(formId, users)
      .then(() => {
        setLoading(false);
        enqueueSnackbar(<FormattedMessage defaultMessage="Users uploaded!"/>, {
          variant: "success"
        });
      })
      .catch(() => {
        setLoading(false);
        enqueueSnackbar(
          <FormattedMessage defaultMessage="Users upload failed!"/>,
          {
            variant: "error"
          }
        );
      });
  };

  useEffect(() => {
    getRecipientsByFormId(formId)
      .then(({ data }) => {
        setUsersForForm(data);
      })
      .catch(() => {});
  }, [formId]);

  const handleRemoveUsers = () => {
    setRemovingUsers(true);
    deleteRecipientsByFormId(formId)
      .then(() => {
        setRemovingUsers(false);
        setUsersForForm([]);
        enqueueSnackbar(<FormattedMessage defaultMessage="Users removed!"/>, {
          variant: "success"
        });
      })
      .catch(() => {
        setRemovingUsers(false);
        enqueueSnackbar(
          <FormattedMessage defaultMessage="Something went wrong!"/>,
          {
            variant: "error"
          }
        );
      });
  };

  if (!isAdmin() || !forms || !state?.formId) {
    return <Redirect to={RoutePaths.MANAGE_FORMS} />;
  }

  if (isFetching) return <FullPageLoader />;

  return (
    <>
      <BackButton />
      <UploadUsersForm
        formIds={forms.map(form => form.id)}
        selectedFormId={state.formId}
        onChangeFormId={setFormId}
        onSubmit={handleUploadUsers}
        isLoading={isLoading}
        isRemovingUsers={isRemovingUsers}
        onRemoveUsers={handleRemoveUsers}
        usersForForm={usersForForm}
      />
    </>
  );
};

export default UploadUsers;

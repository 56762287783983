import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import EditSurvey from "components/EditSurvey";
import SimpleSelect from "components/Select";
import BackButton from "components/BackButton";
import FullPageLoader from "components/FullPageLoader";
import { getAllForms, updateFormById } from "utils/api";

const getAllFormsIds = data =>
  data.map(form => ({ id: form.id, value: form.name }));

const getFormById = (formId, allForms) =>
  allForms.find(form => form.id === formId);

const AdminWrapperForm = () => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [isUpdatingForm, setUpdatingForm] = useState(false);
  const [isFetchingData, setFetchingData] = useState(false);
  const [isChangingForm, setChangingForm] = useState(false);
  const [selectedForm, setSelectedForm] = useState(null);
  const [allForms, setForms] = useState(null);

  const handleSubmitQuestions = ({ questions, options }) => {
    setUpdatingForm(true);
    // potrebujem v povodnom formate posielat!!!
    const updatedData = {
      ...selectedForm,
      name: selectedForm.name,
      questions,
      options,
    };

    updateFormById(updatedData, selectedForm.id)
      .then(resp => {
        setUpdatingForm(false);
        setSelectedForm({
          ...selectedForm,
          ...updatedData
        });
        enqueueSnackbar(
          <FormattedMessage
            defaultMessage="Survey questions updated successfull!"
            id="Survey_questions_updated_successfull!" />,
          {
            variant: "success"
          }
        );
      })
      .catch(err => {
        setUpdatingForm(false);
        enqueueSnackbar(
          <FormattedMessage
            defaultMessage="Failed to update data. Try again later!"
            id="Failed_to_update_data._Try_again_later!" />,
          {
            variant: "error"
          }
        );
      });
  };

  const handleSubmitWelcomePage = welcomePageString => {
    setUpdatingForm(true);
    const updatedData = {
      ...selectedForm,
      welcomePageString
    };
    updateFormById(updatedData, selectedForm.id)
      .then(() => {
        setUpdatingForm(false);
        setSelectedForm({
          ...selectedForm,
          ...updatedData
        });
        enqueueSnackbar(
          <FormattedMessage
            defaultMessage="Survey welcome page updated successfull!"
            id="Survey_welcome_page_updated_successfull!" />,
          {
            variant: "success"
          }
        );
      })
      .catch(err => {
        setUpdatingForm(false);
        enqueueSnackbar(
          <FormattedMessage
            defaultMessage="Failed to update data. Try again later!"
            id="Failed_to_update_data._Try_again_later!" />,
          {
            variant: "error"
          }
        );
      });
  };

  const handleSubmitWelcomePage2 = welcomePageString2 => {
    setUpdatingForm(true);
    const updatedData = {
      ...selectedForm,
      welcomePageString2
    };
    updateFormById(updatedData, selectedForm.id)
      .then(() => {
        setUpdatingForm(false);
        setSelectedForm({
          ...selectedForm,
          ...updatedData
        });
        enqueueSnackbar(
          <FormattedMessage
            defaultMessage="Survey welcome page updated successfull!"
            id="Survey_welcome_page_updated_successfull!" />,
          {
            variant: "success"
          }
        );
      })
      .catch(err => {
        setUpdatingForm(false);
        enqueueSnackbar(
          <FormattedMessage
            defaultMessage="Failed to update data. Try again later!"
            id="Failed_to_update_data._Try_again_later!" />,
          {
            variant: "error"
          }
        );
      });
  };

  const handleColorsPage = settings => {
    setUpdatingForm(true);
    const updatedData = {
      ...selectedForm,
      settings
    };
    updateFormById(updatedData, selectedForm.id)
      .then(() => {
        setUpdatingForm(false);
        setSelectedForm({
          ...selectedForm,
          ...updatedData
        });
        enqueueSnackbar(
          <FormattedMessage
            defaultMessage="Survey colors and logo updated successfull!"
            id="Survey_colors_and_logo_updated_successfull!" />,
          {
            variant: "success"
          }
        );
      })
      .catch(err => {
        setUpdatingForm(false);
        enqueueSnackbar(
          <FormattedMessage
            defaultMessage="Failed to update data. Try again later!"
            id="Failed_to_update_data._Try_again_later!" />,
          {
            variant: "error"
          }
        );
      });
  };

  useEffect(() => {
    setFetchingData(true);
    getAllForms()
      .then(({ data }) => {
        setForms(data);
        if (history.location.state?.formId) {
          setSelectedForm(
            data.find(form => form.id === history.location.state?.formId)
          );
        } else {
          setSelectedForm(data[0]);
        }

        setFetchingData(false);
        enqueueSnackbar(<FormattedMessage defaultMessage="Data loaded!"/>, {
          variant: "success"
        });
      })
      .catch(err => {
        enqueueSnackbar(
          <FormattedMessage defaultMessage="Fetch data failed!"/>,
          {
            variant: "error"
          }
        );
        setFetchingData(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectFormById = formId => {
    setChangingForm(true);
    setSelectedForm(getFormById(formId, allForms));
    setTimeout(() => {
      setChangingForm(false);
    }, 500);
  };

  if (isFetchingData || !selectedForm) return <FullPageLoader />;

  return (
    <>
      <BackButton />
      <br />
      <br />
      <SimpleSelect
        data={getAllFormsIds(allForms)}
        defaultSelected={selectedForm.id}
        onChange={handleSelectFormById}
      />
      <br />
      <br />
      {isChangingForm ? (
        <FullPageLoader />
      ) : (
        <EditSurvey
          questionsFormProps={{
            onSubmit: handleSubmitQuestions,
            isLoading: isUpdatingForm,
            questionsData: selectedForm.questions,
            optionsData: selectedForm.options,
            isLocked: false
          }}
          colorsFormProps={{
            onSubmit: handleColorsPage,
            isLoading: isUpdatingForm,
            settings: selectedForm.settings,
            isLocked: false
          }}
          welcomePageFormProps={{
            onSubmit: handleSubmitWelcomePage,
            isLoading: isUpdatingForm,
            welcomePageString: selectedForm.welcomePageString,
            isLocked: false
          }}
          welcomePageFormProps2={{
            onSubmit: handleSubmitWelcomePage2,
            isLoading: isUpdatingForm,
            welcomePageString: selectedForm.welcomePageString2,
            isLocked: false
          }}
        />
      )}
    </>
  );
};

export default AdminWrapperForm;
